@import "../../assets/styles/helpers";

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrapper {
  overflow: hidden;
  width: calc(100% + 40px);
  position: relative;
  left: -20px;
  @include media("md") {
    width: 100%;
    left: 0;
  }

  &__backgroundContent {
    padding: 27px;
    background-color: color("dawn-pink");
  }
  &__leftTasks {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: color("primary-light");
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 19px;
    &__heading {
      color: color("primary");
      font-family: Lora;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
    &__subtitle {
      font-size: 18px;
      > span {
        color: color("green");
        font-weight: 700;
      }
    }
    @include media("lg") {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 29px;
    }

    &__pagination {
      display: flex;
      align-items: center;
      .arrow {
        position: relative;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: color("white");
        margin-left: 17px;
        cursor: pointer;
        &:first-child {
          margin-left: 15px;
        }

        > svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .disabled {
        opacity: 0.5;
        cursor: default;
      }
      .arrowNext {
        transform: rotate(180deg);
      }
    }
  }
  .showMoreButton {
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    color: color("primary");
    border-radius: 18px;
    border: 1px solid color("pink");
    @include media("lg") {
      display: none !important;
    }
  }
  .emptyView {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
      color: color("primary");
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 24px;
    }
    &__button {
      position: relative;
      color: color("primary-light");
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: color("input-border");
      }
    }
  }
}

.wrapperResourcesList {
  width: 100%;
  min-height: 50px;
}
