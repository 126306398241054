@import "../../assets/styles/helpers";

.book {
  &Title {
    font-weight: 500;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 32px;
    // padding: 32px;
  }
  &Subtitle {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 15px;
  }
  .block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    &Left {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: color("primary-light");
    }
    &Right {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: color("brown");
    }
  }
}

.title {
  color: #87756c;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
}
.speciality {
  color: color("primary-light");
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 24px;
  margin-right: 16px;
}
.doctors {
  // padding: 0 32px;
  .groupTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 5px;
    margin-bottom: 5px;
    color: color("brown");
  }
  .spacer {
    margin-bottom: 10px;
  }
}
.selectContainer {
  display: flex;
  align-items: baseline;
  line-height: 40px;
  padding-bottom: 25px;
  position: relative;
}
.line {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 1px;
  background-color: color("light-gray");
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.arrow {
  vertical-align: middle;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (max-width: 480px) {
  .book {
    padding: 32px;
    &Title {
      font-size: 28px;
    }
    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      &Left {
        font-size: 18px;
        line-height: 26px;
      }

      &Right {
        display: none;
      }
    }
  }
}
