@import "../../assets/styles/helpers";

.element {
  position: relative;
  z-index: 1;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 700;
  overflow: hidden;
  transition: $time-transition ease;
  display: flex;
  align-items: center;

  img,
  svg,
  png {
    margin-right: 5px;
  }
}
.disabled {
  cursor: default;
  opacity: 0.4;
}

.primary {
  background-color: color("green");
  color: color("white");
  border: 2px solid color("green");
  box-shadow: 0 4px 13px rgba(217, 181, 173, 0.8);

  &:hover:not(.disabled) {
    background-color: color("white");
    color: color("green");
  }
}

.secondary {
  background-color: color("primary-light");
  color: color("white");
  border: 2px solid color("primary-light");
  box-shadow: 0 4px 13px rgba(217, 181, 173, 0.8);

  &:hover:not(.disabled) {
    background-color: color("white");
    color: color("primary-light");
  }
}

.alternate {
  background-color: color("pink");
  color: color("primary");
  border: 2px solid color("pink");
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);

  &:hover:not(.disabled) {
    background-color: color("white");
  }
}

.rounded {
  padding: 1.8rem 4.8rem;
  border-radius: 25px;
}

.squared {
  height: 62px;
  padding: 18px 24px;
  border-radius: 25px;
}
