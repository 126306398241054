html {
  font-size: 56.25%;
  height: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  height: 100%;
  font-family: $lato;
  font-size: 1.4rem;
  line-height: 2rem;
  background: color("white");
  color: color("primary");

  &.menuOpened {
    overflow: hidden;
  }

  @include media("lg") {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.no-chat {
  #hubspot-messages-iframe-container {
    display: none !important;
  }
}

img {
  max-width: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
