@import "../../../assets/styles/helpers";

.form {
  padding-top: 0 !important;
}

.text {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;

  span {
    opacity: 0.8;
    font-size: 1.2rem;
    line-height: 150%;
  }
}

.stateText {
  margin-bottom: 1.5rem;
  margin-left: 1rem;
}

.bottomText {
  margin-top: 2rem;
}

.infoBox {
  font-size: 1.6rem;
  margin-top: 1rem;
  p {
    bottom: -3rem;
    margin-bottom: 5rem;

    @include media("lg") {
      position: relative;
      left: -10rem;
      margin-bottom: 10rem;
    }
    &:before {
      background-image: url("../../../assets/images/svg/onboarding-form-adrdress-info.svg");
      top: -2.4rem;

      @include media("lg") {
        background-image: url("../../../assets/images/svg/onboarding-form-adrdress-info2.svg");
      }
    }
  }
}

.infoBoxMobile {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-top: 1rem;
  p {
    bottom: -3rem;
    margin-bottom: 5rem;

    @include media("lg") {
      position: relative;
      left: -10rem;
      margin-bottom: 10rem;
    }
    &:before {
      background-image: url("../../../assets/images/svg/onboarding-form-adrdress-info.svg");
      top: -2.4rem;

      @include media("lg") {
        background-image: url("../../../assets/images/svg/onboarding-form-adrdress-info2.svg");
      }
    }
  }
}

.backgroundIcon {
  position: absolute;
  top: 30%;
  left: -2rem;

  @include media("lg") {
    left: -53rem;
    transform: rotateY(180deg);
    top: 48rem;
  }
}

.wrapper {
  position: relative;
  top: -1.7rem;
  //margin-bottom: 18.4rem;
  @include media("lg") {
    max-width: 32rem;
  }
  > div {
    &:first-child {
      &:after {
        @include media("lg") {
          content: "";
          display: block;
          position: absolute;
          background-image: url("../../../assets/icons/adress_section_icon.svg");
          background-repeat: no-repeat;
          width: 110.5rem;
          height: 120.7rem;
          left: -80rem;
          top: 31rem;
          z-index: -1;
        }
      }
      &:before {
        content: "";
        display: block;
        background-image: url("../../../assets/images/svg/onboarding-form-doctor-icon.svg");
        background-repeat: no-repeat;
        width: 11.5rem;
        height: 12.7rem;
        position: relative;
        right: -20rem;
        z-index: 2;

        @include media("lg") {
          background-image: url("../../../assets/images/svg/onboarding-from-adress-girl.svg");
          width: 22rem;
          height: 35.3rem;
          position: absolute;
          left: -27rem;
          top: 3rem;
        }
      }
    }
  }
}

.BackgroundIcon {
  position: absolute;
  bottom: 0;
  left: 0%;
}
