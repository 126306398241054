@import "../../assets/styles/helpers";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.insuranceForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 385px;
  min-height: auto !important;
  padding: 0 !important;

  & > * {
    width: 100%;
  }
}

.description {
  max-width: 385px;
  width: 100%;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.formButton {
  width: 100%;
  max-width: 210px;
  margin-top: 24px;

  &:last-child {
    margin-top: 0;
  }
}

.response {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;

  span {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

.yes {
  color: color("green");
}

.no {
  color: color("alert");
}

.link {
  text-decoration: underline;
}

.bottomLink {
  text-decoration: underline;
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
}

.drawer {
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: auto;
  height: 90% !important;
  overflow-x: hidden;
  padding: 64px 16px;
  &MobileMenu {
    height: auto !important;
    padding: 0;
  }
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  & img {
    width: 42px;
    height: 42px;
  }
}
