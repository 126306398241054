.element {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;

  max-width: 123rem;

  &.isFluid {
    max-width: 100%;
  }
}
