@import "../../assets/styles/helpers";

.blog {
  display: flex;
  justify-content: center;
  &Title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: color("primary-light");
    margin-bottom: 25px;
    max-width: 100%;
  }
  &Text {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: color("primary-light");
    height: auto;
    margin-bottom: 3px;
    & iframe {
      margin: 15px auto;
    }
  }
  &Iframe {
    display: flex;
  }
  &List {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: color("primary-light");
    display: flex;
  }
  .heading3 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  ul {
    list-style: revert;
    margin-left: 20px;
  }

  a {
    color: color("green");
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.wrapperSkeleton {
  margin-top: 40px;
}

.button {
  border: 1px solid color("pink");
  background-color: color("white");
  color: color("primary");
}

.mark {
  order: 1;
  margin-top: 10px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  @include media("xl") {
    order: 0;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }

  @media (min-width: 575px) {
    margin-bottom: 0;
  }
}
.markAsDone {
  width: 100%;
  background: color("green");
  color: color("white");
  text-align: center;
  border-radius: 25px;
  padding: 18px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  margin: 0 32px;
  cursor: pointer;
  @include media("lg") {
    margin: 0;
  }
}

.done {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: color("primary");
  margin-left: 32px;
  cursor: pointer;
  > svg {
    margin-right: 8px;
    width: 42px;
    height: 42px;
  }

  @media (min-width: 575px) {
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .blog {
    padding: 32px;
    &Text {
      font-size: 14px;
      line-height: 22px;
      & iframe {
        max-width: 320px;
        margin: 0 auto 15px;
      }
    }
  }
}
