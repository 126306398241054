@import "../../../assets/styles/helpers";

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 16px;

  @media (min-width: 575px) {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
  }
}

.subtitle {
  text-align: center;
  font-style: italic;
  margin-bottom: 16px;
  font-size: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 32px;
  gap: 16px;

  @media (min-width: 575px) {
    padding: 0;
  }
}

.formField {
  width: 100%;
  max-width: 350px;
}

.formButton {
  width: 100%;
  max-width: 235px;
}

.errorContainer {
  display: flex;
  align-items: end;
  text-align: center;
  min-height: 80px;
  width: 100%;
  max-width: 340px;
  font-size: 14px;
  color: color("alert");
}
