@import "../../assets/styles/helpers";

.doctor {
  display: flex;
  justify-content: space-between;
  background: color("white");
  border: 1px solid color("light-gray");
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 20px;
  &Info {
    margin-left: 20px;
  }
  &Name {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  &Stack {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: color("brown");
    margin-bottom: 10px;
  }
  &Bio {
    display: flex;
  }
  &Image {
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      max-width: 100px;
      max-height: 100px;
      border-radius: 15px;
      vertical-align: middle;
      border-radius: 50%;
    }
  }
  .exp {
    display: flex;
    margin-bottom: 15px;
    &Block {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
    &Text {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      margin-left: 5px;
    }
    &Icon {
      width: 25px;
      height: 25px;
    }
  }
  .about {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: 1px solid color("input-border");
  }
}
.bookButton {
  padding: 10px 20px;
  margin-left: 20px;
  height: 40px;
  width: 75px;

  svg {
    height: 14px;
    width: 14px;
    margin: 0;
  }

  @media (max-width: 576px) {
    margin-left: 0;
    display: block;
    width: 100%;
  }
}

.inVisible {
  display: none;
}

.mobileLink {
  display: none;
}

@media (max-width: 576px) {
  .doctor {
    flex-direction: column;
    justify-content: center;
    &Bio {
      margin-bottom: 20px;
    }
  }
  .mobileLink {
    display: inline;
  }

  .bookButton {
    padding: 10px 80px;
  }
}

@media (max-width: 480px) {
  .doctor {
    padding: 16px;
    &Image {
      & img {
        width: 85px;
        height: 85px;
      }
    }
  }
  .bookButton {
    padding: 10px 60px;
  }
}

@media (max-width: 400px) {
  .doctor {
    padding: 15px;
    &Image {
      & img {
        width: 75px;
        height: 75px;
      }
    }
  }
}
