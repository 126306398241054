@import "../../assets/styles/helpers";

.card {
  background-color: color("white");
  border-radius: 32px;
  padding: 36px;
  text-align: center;

  .subHeading {
    color: color("green");
  }

  .button {
    width: 100%;
    margin-top: 16px;
  }

  .leftIcon {
    position: absolute;
    left: -50px;
    width: 90px;
    height: 99px;
    z-index: -1;
  }
  .rightIcon {
    position: absolute;
    right: -50px;
    width: 72px;
    height: 112px;
    z-index: -1;
  }
}
