@import "../../assets/styles/helpers";

// Text Size

.small {
  font-size: 1.3rem;
  line-height: 1.4rem;

  @include media("lg") {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.medium {
  font-size: 1.4rem;
  line-height: 2.25rem;

  @include media("lg") {
    font-size: 1.6rem;
    line-height: 2.24rem;
  }
}

.large {
  font-size: 1.8rem;
  line-height: 2.8rem;

  @include media("lg") {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.title {
  font-size: 3rem;
  line-height: 3.3rem;

  @include media("lg") {
    font-size: 7rem;
    line-height: 7.7rem;
  }
}

.h1 {
  font-size: 7rem;
  line-height: 7.7rem;
}

.h2 {
  font-size: 3.6rem;
  line-height: 3.9rem;

  @include media("lg") {
    font-size: 4.5rem;
    line-height: 4.95rem;
  }
}

.h3 {
  font-size: 1.8rem;
  line-height: 2.34rem;

  @include media("lg") {
    font-size: 3.4rem;
    line-height: 4.76rem;
  }
}

.h4 {
  font-size: 1.8rem;
  line-height: 2.25rem;

  @include media("lg") {
    font-size: 2.4rem;
    line-height: 2.76rem;
  }
}

.h1.h2.h3.h4 {
  text-align: center;
}

// Text Colors

.primary {
  color: color("primary");
}

.primaryLight {
  color: color("primary-light");
}

.green {
  color: color("green");
}

.pinkLight {
  color: color("pink-light");
}

.pink {
  color: color("pink");
}

.pinkDarker {
  color: color("pink-darker");
}

.white {
  color: color("white");
}

.whiteDirty {
  color: color("white-dirty");
}

.alert {
  color: color("alert");
}

// Font Weights

.w100 {
  font-weight: 100;
}

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.w800 {
  font-weight: 800;
}

.w900 {
  font-weight: 900;
}
