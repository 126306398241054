@import "../../../../assets/styles/helpers";

.wrapper {
  margin-bottom: 2.2rem;
}

.element {
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.box {
  background-color: color("white");
  position: relative;
  top: 0.45rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(94, 100, 103, 0.2);
  width: 2.2rem;
  height: 2.2rem;
  min-width: 2.2rem;
  border-radius: 4px;
  margin-right: 1.6rem;

  &.isActive {
    border-color: color("green");
    background-color: color("green");
    svg {
      path {
        stroke: color("white");
      }
    }
  }
}

.label {
  margin-top: 0.5rem;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: inline;
  align-items: center;
  color: color("primary-light");
  user-select: none;

  a {
    border-bottom: 1px solid transparentize(color("primary"), 0.6);

    &:hover {
      color: color("green");
      border-bottom-color: color("green");
    }
  }
}
