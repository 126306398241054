@import "../../assets/styles/helpers";

@include media("xl") {
  .backgroundCircleTop {
    position: absolute;
    left: 50%;
    top: -40%;
    transform: translateX(-50%);
    background: rgba(248, 157, 156, 0.2);
    filter: blur(200px);
    width: 800px;
    height: 800px;
  }
}

.root {
  background-color: #fbf8f3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 18px;
  padding: 48px 12px;

  @include media("xl") {
    &::before {
      content: "";
      background-image: url("../../assets/icons/condition_icon_left.svg");
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 10%;
      width: 100%;
      height: 300px;
    }
    &::after {
      content: "";
      background-image: url("../../assets/icons/condition_icon_right.svg");
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 40%;
      width: 537px;
      height: 345px;
    }
  }

  img {
    z-index: 10;
    margin-bottom: 24px;
    height: 360px;
    @include media("xxxl") {
      height: 460px;
    }
  }
}

.heading {
  color: #3f2c26;
  text-align: center;
  font-family: Lora;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  max-width: 390px;
  @include media("md") {
    font-size: 28px;
    line-height: 40px;
  }
}
.bodyOne {
  color: #5b443c;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 390px;
  @include media("md") {
    font-size: 20px;
    line-height: 32px;
  }
}

.nextButton {
  padding: 0;
}
