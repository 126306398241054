@import "../../assets/styles/helpers";

.element {
  width: 2.4rem;
  height: 1.6rem;
  margin-right: 3rem;
  min-width: 2.4rem;
  @media screen and (max-width: 388px) {
    margin-right: 1.4rem;
    min-width: 2rem;
  }
}

.plank {
  width: 100%;
  background-color: color("primary");
  height: 0.2rem;
  border-radius: 1.1rem;
}
