@import "../../assets/styles/helpers";

.hide {
  display: none !important;
}

.firstColumnText {
  color: white;
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.89rem;
  font-style: italic;
  opacity: 0.7;
  padding-bottom: 1rem;

  @include media("lg") {
    padding-top: 4rem;
  }
}

.upperSectionContainer {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/grain-green.png");

  .upperSection {
    padding-top: 5rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @include media("lg") {
      flex-direction: row;
      justify-content: space-between;
      align-items: initial;
      padding-top: 11rem;
      padding-bottom: 16rem;
    }

    .navigationAndDownloadContainer {
      width: 100%;

      @include media("lg") {
        width: 28%;
      }

      .navigationMenu {
        font-size: 1.4rem;
        font-family: $lato;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #ffffff;

        .navigationLink {
          padding: 1.5rem 0;
        }

        @include media("lg") {
          font-size: 1.6rem;
        }
      }

      .navigationMenu:nth-child(1) {
        .navigationLink {
          padding-top: 0;
        }
      }

      .downloadButtonContainer {
        padding-top: 4.5rem;
        padding-bottom: 4rem;
        width: 100%;

        display: flex;
        flex-direction: column;

        .downloadButton {
          max-width: 20rem;

          @media screen and (max-width: 991px) {
            max-width: 100%;
          }

          &:nth-child(1) {
            margin-bottom: 2rem;
          }
        }

        @include media("lg") {
          padding-top: unset;
          padding-bottom: 2rem;
        }
      }
    }

    .keepInTouchColumn {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: color("white");
      padding-top: 5rem;
      max-width: 100%;

      @include media("lg") {
        max-width: 30rem;
      }

      @include media("lg") {
        padding-top: 0;
      }

      .keepInTouchText {
        font-size: 1.4rem;
        padding-top: 0.8rem;
        line-height: 1.89rem;
        opacity: 0.7;
      }

      .inputContainer {
        margin: 1.8rem 0 1.8rem 0;
        width: 100%;
      }

      .iconsContainer {
        display: flex;
        padding-top: 4rem;

        .icon {
          width: 11.5rem;
        }

        .icon:nth-child(1) {
          justify-self: flex-start;
        }

        .icon:nth-child(2) {
          justify-self: center;
          margin-left: 4rem;

          @include media("lg") {
            justify-self: flex-end;
            margin-left: auto;
          }
        }
      }
    }

    .contactsColumn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 24rem;
      color: color("white");
      width: 100%;

      @include media("lg") {
        width: auto;
      }

      .phoneContact {
        display: flex;
        flex-direction: row;

        .retroPhone {
          margin-right: 2rem;
          height: 2.4rem;
          width: 2.4rem;
        }

        .phoneNumberText {
          line-height: 2.5rem;
          margin-bottom: 1rem;
        }

        .days {
          font-size: 1.6rem;
          font-weight: 500;
        }

        .message {
          font-size: 1.4rem;
          font-weight: 500;
          opacity: 0.7;
          line-height: 1.4;
          margin-bottom: 2.5rem;
        }
      }

      .emailContact {
        display: flex;
        align-items: center;
        padding-top: 1rem;

        .emailImage {
          margin-right: 2rem;
          height: 2.4rem;
          width: 2.4rem;
        }

        .emailText {
          border-bottom: 2px solid rgba(255, 255, 255, 0.2);
          font-family: $lora;
          padding-bottom: 0.2rem;
        }
      }

      .followUs {
        margin-top: 3.6rem;

        .followUsHeading {
          margin-bottom: 2rem;
        }
      }

      .iconsRow {
        display: flex;
        justify-self: flex-end;
        margin-top: auto;
        width: 28.3rem;

        .iconRowElement {
          opacity: 0.7;
          margin-right: 4rem;
          height: 2.5rem;
          width: 2.5rem;

          img {
            width: 2.6rem;
            height: 2.6rem;
            max-width: unset;
          }
        }
      }
    }
  }
}

.bottomSectionContainer {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/dark-green-background.jpg");

  @include media("lg") {
    max-height: 7.3rem;
    height: 73px;
  }

  .bottomSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 2.5rem;
    padding-bottom: 3rem;

    @include media("lg") {
      padding-top: 0;
      padding-bottom: 0;
      flex-direction: row;
      align-items: center;
    }

    @include media("lg") {
      flex-direction: row;
    }

    .navigationsContainer {
      display: flex;
      height: 100%;
      flex-direction: column;

      @include media("xs") {
        flex-direction: row;
        justify-content: space-between;
      }

      .bottomNavigationLinkContainer {
        font-size: 1.4rem;
        color: #ffffff;
        padding: 1rem 0;

        @include media("lg") {
          font-size: 1.6rem;
        }
      }

      .additionalNavigation {
        display: flex;
        flex-direction: column;

        @include media("lg") {
          flex-direction: row;
          font-size: 1.6rem;
        }

        .additionalNavigationLinkContainer {
          color: #ffffff;
          opacity: 0.7;
          font-size: 1.4rem;
          padding: 1rem 0;

          @include media("lg") {
            display: flex;
            justify-content: center;
            font-size: 1.6rem;
            align-items: center;
            padding-right: 3rem;
          }
        }
      }
    }

    .bottomSectionText {
      color: #ffffff;
      opacity: 0.7;
      font-size: 1.6rem;
    }

    .bottomSectionText:last-child {
      padding-top: 3.5rem;
      padding-bottom: 4rem;

      @include media("lg") {
        justify-self: flex-end;
        margin-left: auto;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .bottomSectionText {
      @include media("lg") {
        margin-left: 3.67%;
      }
    }
  }
}
