@import "../../../../assets/styles/helpers";

.Root {
  display: block;
  padding: 50px 32px;
  z-index: 200;
  position: relative;
  display: flex;
  flex-direction: column;
  @include media("lg") {
    overflow: hidden;
    z-index: 10;
    padding: 25px 40px;
    padding-bottom: 0;
  }
  .BackgroundIcon {
    position: absolute;
    left: -40px;
    bottom: -40px;
    z-index: -1;
  }
  .header {
    font-weight: 500;
  }
  .subheader {
    font-family: "Lato";
    font-size: 1.8rem;
    line-height: 130%;
    text-align: center;
    margin: 1.2rem auto 0.6rem;
    @include media("lg") {
      margin: 1.2rem auto 4.8rem;
    }
  }
}
