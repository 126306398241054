@import "../../../../assets/styles/helpers";

.element {
  margin-bottom: 2.7rem;
  margin-left: 2.5rem;
}

.title {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.icon {
  margin-right: 1rem;
  path {
    stroke: color("disabled");
  }
}

.label {
  font-weight: 500;
  font-size: 1.4rem;
}

.item {
  margin-bottom: 1rem;
  color: color("primary-light");
  opacity: 0.8;

  &.isActive {
    .label {
      color: color("green");
    }
    .icon {
      path {
        stroke: color("green");
      }
    }
  }
}
