@import "../../../assets/styles/helpers";

.backgroundIcon {
  position: absolute;
  top: 23%;
  left: -32rem;
  z-index: -1;

  @include media("lg") {
    top: 0;
    left: -93rem;
    z-index: -1;
  }
}

.backgroundIcon2 {
  position: absolute;
  top: 23%;
  right: 0;
  z-index: -1;
  transform: rotate(180deg);

  @include media("lg") {
    top: 0;
    left: -93rem;
    z-index: -1;
  }
}
.drawer {
  background-color: color("white-dirty") !important;
  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 201;
    & img {
      width: 42px;
      height: 42px;
    }
  }
}
.backgroundIcon2 {
  display: none;
  @include media("lg") {
    display: block;
    float: right;
    top: 36%;
    width: 48vw;
    z-index: 12;
    transform: rotate(180deg) translateX(-136rem);
  }
}

.errorMessage {
  color: color("alert");
  font-size: 13px;
  margin-bottom: 10px;
}

.checkboxWrapper {
  margin-top: 5.5rem;
}

.infoBox {
  @include media("lg") {
    position: absolute;
    right: 0rem;
    top: 14rem;
  }
  p {
    margin-bottom: 9rem;
    &:before {
      background-image: url("../../../assets/images/svg/onboarding-form-info-icon_1.svg");
    }
  }
}

.infoBoxMobile {
  @include media("lg") {
    position: absolute;
    right: 6rem;
    top: 12rem;
  }
  p {
    margin-bottom: 3rem;
    &:before {
      height: 20rem;
      top: -2rem;
      margin-left: 1rem;
      background-image: url("../../../assets/images/svg/onboarding-form-info-icon_1.svg");
    }
  }
}

.icon {
  position: absolute;
  left: -32rem;
  top: 21%;
  z-index: -1;
}

.header {
  max-width: 65%;
  margin-bottom: 3.5rem;

  @include media("lg") {
    max-width: 100%;
    margin-bottom: 4.8rem;
  }
}

.planDetails {
  margin-bottom: 3.6rem;
  background-color: color("white");
  padding: 1.2rem 1.8rem 2rem;
  box-shadow: 0px 15px 49px rgba(197, 160, 150, 0.35);
  border-radius: 0 0 2.8rem 2.8rem;
  position: relative;
  color: color("primary");

  .planRow {
    padding: 0.6rem 0.4rem 0.6rem;

    .planName {
      font-size: 1.8rem;
      font-weight: 600;
      .mostPopularPlanName {
        background-color: #e99289;
        padding: 2px 4px 2px;
        margin-left: 4px;
        border-radius: 3px 3px 3px 3px;
      }
    }
    .planNameDetail {
      font-size: 1.6rem;
      margin-top: 0.5rem;
      .planDiscount {
        margin-left: 4px;
        font-weight: 600;
        color: #51a596;
      }
    }
  }
}

.promoCard {
  background-color: color("white");
  padding: 10px;
  border-radius: 2.8rem;
  border: 1px solid color("green");
  > p {
    text-align: center;
    color: color("green");
  }
}

.formHeader {
  background-image: url("../../../assets/images/peach.png");
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: repeat;
  padding: 1rem 2.3rem 1rem 2.3rem;
  font-size: 2.4rem;
  border-radius: 35px 35px 0 0;
  line-height: 3.1rem;
  color: color("white");
  margin: 3rem auto auto;

  .formHeaderL1 {
    font-size: 18px;
  }

  .formHeaderL2 {
    font-size: 28px;
    font-weight: 700;
  }

  &:before {
    content: "";
    background-image: url("../../../assets/images/leaves-payment-form-header.svg");
    background-repeat: no-repeat;
    background-size: contain;
    right: -5.4rem;
    top: -4.2rem;
    height: 9rem;
    width: 9rem;
    position: absolute;
    z-index: -1;
  }
}

.form {
  position: relative;
  z-index: 1;
  background-image: url("../../../assets/images/payments_background.png");
  background-repeat: repeat;

  min-height: 100%;

  padding: 2rem 1rem;

  @media screen and (max-width: 575px) {
    background-size: contain;
  }
}

.formBackground {
  border-radius: 35px;
}

.planPickerHeader {
  margin-bottom: 1.7rem;
  p {
    font-size: 2.1rem;
    line-height: 2.7rem;
    color: color("primary");
  }

  .planSwitchText {
    padding-top: 0.2rem;
    color: color("primary");
    opacity: 0.6;
  }
}

.photo {
  display: flex !important;
  min-width: 5rem;
  width: 15rem;
  height: 20rem;
  overflow: hidden;
  border-radius: 45%;
  background-color: color("linen");
  justify-content: center;
  position: relative;
  margin-right: 1.2rem;
  margin-bottom: 2.5rem;

  img {
    width: auto;
    height: 100%;
    max-width: unset;
    margin: 0 auto;
  }

  @include media("lg") {
    margin-bottom: 0;
    margin-top: 2rem;
  }
}

.modal {
  background-color: color("white-dirty");
  min-height: auto;
  overflow: hidden;
  max-height: 650px;
  padding: 0;
  display: flex;
}
