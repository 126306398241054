@import "../../assets/styles/helpers";

.link {
  color: color("green");
}
.minHeight {
  min-height: 80vh;
}
.resetPassInput {
  margin-top: 3rem;
}
.submitButton {
  width: auto;
  margin: auto auto 0 auto;
}
.logInText {
  margin-top: 2.5rem;
}
