@include fontface("Lora", "Lora/Lora-Regular", 400);
@include fontface("Lora", "Lora/Lora-Medium", 500);
@include fontface("Lora", "Lora/Lora-SemiBold", 600);
@include fontface("Lora", "Lora/Lora-Bold", 700);

@include fontface("Lato", "Lato/Lato-Hairline", 100);
@include fontface("Lato", "Lato/Lato-Light", 300);
@include fontface("Lato", "Lato/Lato-Regular", 400);
@include fontface("Lato", "Lato/Lato-Bold", 700);
@include fontface("Lato", "Lato/Lato-Black", 900);
