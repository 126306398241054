@import "../../assets/styles/helpers";

.typography {
  font-size: 18px;
  line-height: 32px;
  color: color("primary");
  margin: 12px auto;
  a {
    text-decoration: underline;
  }
}
.button {
  margin-top: 30px;
  width: 100%;
}
