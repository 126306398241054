@import "../../assets/styles/helpers";

.element {
  position: relative;
  min-height: 100vh;
  padding-bottom: 6rem;
  overflow: hidden;
  @media (max-width: 575px) {
    padding-bottom: 0;
  }
}

.noPadding.noPadding {
  padding-bottom: 0;
}

.modalOpen {
  padding-bottom: 0;
  min-height: 100vh;
  overflow: hidden;
}

.noMarginBottom.noMarginBottom {
  margin-bottom: 0;
}

.assets {
  background-image: url("../../assets/images/bg-grain.png");
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  @include media("md") {
    &::before {
      position: absolute;
      display: flex;
      content: "";
      width: 80rem;
      height: 80rem;
      left: 50%;
      top: 0;
      background: rgba(248, 157, 156, 0.2);
      filter: blur(200px);
      border-radius: 50%;
      transform: translate(-50%, -80%) translateZ(0);
      -webkit-perspective: 1000;
      -webkit-backface-visibility: hidden;
    }

    &::after {
      position: absolute;
      display: flex;
      content: "";
      width: 80rem;
      height: 80rem;
      left: 50%;
      bottom: 0;
      background: rgba(248, 157, 156, 0.2);
      filter: blur(200px);
      border-radius: 50%;
      transform: translate(-50%, 80%) translateZ(0);
      -webkit-perspective: 1000;
      -webkit-backface-visibility: hidden;
    }
  }

  .rainbow {
    position: absolute;
    z-index: 20;
    background-image: url("../../assets/images/svg/circle.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;

    height: 100%;
    bottom: -53rem;

    @include media("lg") {
      background-size: cover;
      top: 70%;
      bottom: unset;
    }
  }
}

.paymentsMinHeight.paymentsMinHeight {
  height: 100%;
}

.background {
  background: black;
}

.header {
  position: relative;
  z-index: 91;
  padding: 2.5rem 1rem;

  @include media("sm") {
    padding: 2.5rem 9rem 5rem 9rem;
  }
}

.contentWrapper {
  margin: auto auto 0 auto;
  width: 100%;
  max-width: 50rem;
  padding: 0 1rem;

  @media (max-width: 575px) {
    flex-grow: 1;
    height: 100%;
  }

  @include media("lg") {
    padding: unset;
  }

  @include media("sm") {
    margin-bottom: auto;
  }
}
.addedHomePage {
  max-width: 100%;
}

.marginBottomForPayments.marginBottomForPayments {
  padding: unset;
}

.content {
  position: relative;
  z-index: 91;

  @media (max-width: 575px) {
    height: 100%;
  }

  @include media("sm") {
    border-radius: 45px;
  }
}

.backgroundIcon {
  position: absolute;
  top: 23%;
  left: -32rem;
  z-index: -1;

  @include media("lg") {
    top: 0;
    left: -93rem;
    z-index: -1;
  }
}

.backgroundIcon2 {
  position: absolute;
  top: 23%;
  right: 0;
  z-index: -1;
  transform: rotate(180deg);

  @include media("lg") {
    top: 0;
    left: -93rem;
    z-index: -1;
  }
}

.backgroundIcon2 {
  display: none;
  @include media("lg") {
    display: block;
    float: right;
    top: 36%;
    width: 48vw;
    z-index: 12;
    transform: rotate(180deg) translateX(-136rem);
  }
}

.wrapperLoading {
  padding: 2.4rem;
  max-height: 51rem;
  margin: auto;

  @include media("340px") {
    min-height: 62rem;
    padding: 4rem;
    height: 100vh;
  }

  @include media("sm") {
    min-height: 62rem;
    padding: 4rem;
  }

  @include media("lg") {
    min-height: 62rem;
    padding: 4rem;
    height: unset;
  }

  .loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      stroke: color("green");
    }
  }

  display: flex;
  justify-content: center;
}

.wrapper {
  background-color: transparent;
  height: 100%;
  @include media("sm") {
    overflow-y: unset;
    height: 100%;
    &.hasTopBar {
      background-color: color("white");
      border-radius: 0 0 45px 45px;
    }
  }

  :global(form) {
    padding: 2.4rem;
    @include media("sm") {
      min-height: 62rem;
      padding: 4rem 4rem 2rem 4rem;
      height: 100%;
    }
  }
}

.transparent {
  opacity: 0;
}

.stepNumber {
  min-height: 5.4rem;
  margin-top: -1rem;
  display: flex;
  justify-self: center;
  align-items: center;

  .label {
  }
}

.logo {
  margin: 0 auto;
}

.posRelative {
  position: relative;
  z-index: 90;
}

.stepperBackButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3.4rem;
  width: 3.4rem;
  height: 3.4rem;
  background-color: color("white");
  border-radius: 50%;
  margin-right: 1.3rem;

  svg {
    opacity: 0.6;
  }

  &:hover {
    background-color: color("green");

    svg {
      opacity: 1;

      path {
        stroke: color("white");
      }
    }
  }

  @include media("sm") {
    position: relative;
    left: 0;
  }
}

.headerInner {
  height: 4.5rem;
  @include media("sm") {
    margin-bottom: 0;
  }
}
