@import "../../assets/styles/helpers";

.wrapper {
  position: relative;
  width: 100%;
  border-radius: 25px;
  border: 2px solid color("green");
  background-color: color("white");
  overflow: hidden;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @include media("xl") {
    flex-direction: row;
    min-height: 100px;
    padding: 0 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 100%;
    background-color: color("green");
  }

  &__content {
    width: 100%;
    margin-bottom: 15px;
    @include media("xl") {
      width: 55%;
      margin-bottom: 0;
    }
    &__image {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 16px;
      @include media("xl") {
        margin-right: 18px;
      }
    }
    &__iconText {
      display: flex;
      align-items: center;

      svg {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: color("green");
        width: 80%;
        @include media("xxl") {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
        }
      }
    }
  }
  &__mark {
    order: 1;
    margin-top: 10px;
    display: flex;
    align-items: center;
    @include media("xl") {
      order: 0;
      margin-top: 0px;
      width: 15%;
      justify-content: flex-end;
    }
  }
  .markAsDone {
    width: fit-content;
    position: relative;
    color: color("primary-light");
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 5px;
      background-color: color("input-border");
    }
  }

  .button {
    width: 172px;
    height: 48px;
    box-shadow: none;
  }
}

//STYLE FOR COMPLETED CARD:
.wrapperDone {
  border: 2px solid color("primary");
  &::before {
    background-color: color("primary");
  }

  .wrapper__content {
    .title {
      color: color("primary");
    }
  }
  .done {
    display: flex;
    color: color("primary");
    cursor: pointer;
    > svg {
      margin-right: 8px;
    }
  }
  .button {
    border: 1px solid color("pink");
    background-color: color("white");
    color: color("primary");
  }
}
