@import "variables/colors.module";
@import "variables/breakpoints.module";

$time-transition: 0.2s;
$lato: "Lato", sans-serif;
$lora: "Lora", serif;

@import "./mixins/media";
@import "./mixins/fontface";
@import "./mixins/color";
@import "./mixins/scrollbar";
