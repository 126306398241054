@import "../../../../assets/styles/helpers";

.wrapper {
  margin-bottom: 1.3rem;
}

.element {
  position: relative;
  z-index: 1;
  outline: none !important;
  background-color: transparent;
  padding: 2.4rem 2.4rem 0.6rem 2.4rem;
  border-radius: 25px;
  border: 1px solid color("input-border");
  width: 100%;
  font-weight: 500;
  color: color("primary");
  font-size: 1.6rem;
  line-height: 3rem;
}

.label {
  position: absolute;
  top: 0;
  font-size: 1.6rem;
  font-weight: 500;
  transform-origin: top left;
  line-height: 3rem;
  transform: scale(0.75) translateY(0.5rem);
  z-index: 2;
  opacity: 1;
}
