@import "../../assets/styles/helpers";

.doctor {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid color("light-gray");
  border-radius: 25px;
  padding: 50px 20px 20px 24px;
  margin-bottom: 20px;
  overflow: hidden;
  &RecommendedHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background-color: #fbf8f3;

    font-family: Lato;
    color: #3f2c26;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
  }
  &Top {
    border-bottom: 1px solid color("light-gray");
    width: 100%;
    padding-bottom: 17px;
    margin-bottom: 17px;
  }
  &Book {
    display: flex;
    justify-content: space-between;
  }
  &Info {
    width: 100%;
    &Content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 10px;
      &Left {
        display: flex;
        align-items: center;
      }
    }
    &Block {
      margin-left: 10px;
    }
    &Bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: color("primary");
      font-family: Lato;
    }
  }
  &Image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  &Name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: color("primary");
  }
  &Stack {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: color("brown");
  }
  &Date {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #5b443c;
    strong {
      font-weight: 700;
    }
  }
}

.bookBtn {
  background-color: #fff;
  border: 1px solid #e5beb0;
  color: #3f2c26;
  padding: 18px 24px;
  border-radius: 15px;
  box-shadow: none;
  width: 75px;
  height: 40px;
}
