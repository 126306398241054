@import "../../assets/styles/helpers";

.root {
  background-color: color("white-dirty");
  min-height: 112px;
  position: relative;
  display: flex;
  flex-direction: column;
  &Success {
    position: fixed;
    top: 20px;
    right: 0;
    background: color("white");
    padding: 20px;
    width: 300px;
    z-index: 4;
    font-size: 18px;
    font-weight: 700;
    font-family: $lora;
    color: color("green");
    transition: all ease-in 0.5s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 4px;
  }
}

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 120rem;
}

.form {
  &Item {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    background-blend-mode: overlay, normal;
    border-radius: 25px;
    cursor: pointer;
    padding: 28px 32px;
    background-color: color("white-dirty");
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    &Selected {
      border: 1px solid color("green");
    }
    &Red {
      color: color("cancel-red");
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: color("white");
  padding: 30px 0;
  margin-top: 77px;
  &Header {
    display: flex;
    padding: 0 140px;
  }
  &Tabs {
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 120rem;
    background-color: color("white");
  }

  &Form {
    &Button {
      display: flex;
      margin: 48px auto 0;
      background: color("green");
      border-radius: 20px;
      padding: 13px 44px;
      color: color("white");
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      &:disabled {
        opacity: 0.5;
      }
    }

    &Block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &Wrap {
    max-width: 850px;
    padding-left: 250px;
    padding-top: 40px;
  }
}

.downgrade {
  &Option {
    position: relative;
    padding: 24px;
    border-radius: 25px;
    background-color: color("white-dirty");
    margin-bottom: 12px;
    color: color("primary");
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    @media (min-width: 1200px) {
      margin-bottom: 32px;
      font-size: 20px;
      padding: 24px 32px;
      line-height: 30px;
    }
    &Title {
      font-weight: bold;
    }
    &Membership {
      font-size: 16px;
      position: absolute;
      top: 24px;
      right: 24px;
      color: #92756d;
      font-style: italic;
      @media (min-width: 1200px) {
        font-size: 19px;
      }
    }
    &Active {
      background-color: color("dawn-pink");
      box-shadow: 0px 0px 0px 3px color("primary");
    }
    &:last-of-type {
      margin-bottom: 0;
      h3 {
        font-weight: 500;
      }
    }
  }
}

.button {
  width: 262px;
  text-align: center;
  padding: 18px 0;
  border-radius: 25px;
  margin: 12px;
  color: color("white");
  font-weight: 700;
  &Confirm {
    background-color: color("green");
  }
  &Cancel {
    background-color: color("primary-light");
  }
}

.heading {
  font-size: 21px;
  font-family: $lora;
  line-height: 30px;
  font-weight: 500;
  margin-left: 60px;
  width: 85%;
  // margin: 0 auto;
  &Center {
    text-align: center;
  }
}

.subheading {
  font-size: 16px;
  margin-top: 10px;
  font-family: $lato;
  line-height: 30px;
  color: color("primary-light");
  font-style: italic;
}

.textareaField {
  width: 100%;
  height: 120px;
  border-radius: 25px;
  border-color: color("light-gray");
  padding: 17px 24px;
  &:focus {
    outline: none;
    border-color: color("green");
  }
}

@media (max-width: 768px) {
  .textareaField {
    height: 200px;
  }
}

.backArrow {
  cursor: pointer;
}

.mobile {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: color("white");
  &Title {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    font-family: $lora;
  }
}

.wrapperConfirmButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 100rem;
  }
  .content {
    &Header {
      display: flex;
      padding: 0 10px;
    }
    &Tabs {
      max-width: 100rem;
    }
    &Wrap {
      max-width: 100%;
      padding-left: 0px;
    }
    &Form {
      &Block {
        flex-direction: column;
        align-items: flex-start;
      }
      &Button {
        align-self: center;
        margin: 32px auto 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 30px 0;
    margin-top: 40px;
    &Header {
      display: flex;
      flex-direction: row;
      padding: 0 20px;
    }

    &Wrap {
      max-width: 850px;
      padding-left: 0px;
      padding-top: 40px;
    }
  }
}
@media (max-width: 576px) {
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 30px 0;
    margin-top: 76px;
    &Header {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
    &Wrap {
      max-width: 850px;
      padding-left: 0px;
      padding-top: 40px;
    }
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 24px;
    margin-left: 20px;
    width: 85%;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 24px;
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .button {
    display: block;
    margin: 24px auto 0;
  }
}
