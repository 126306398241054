@import "../../assets/styles/helpers";

.wrapper {
  width: 200px;
  position: relative;
}

.hiddenSelect {
  display: none;
}

.inputContainer {
  color: #164f43;
  border: 1px solid color("pink");
  border-radius: 18px;
  outline: none;
  background-color: color("white");
  padding: 9px 24px;
  display: inline;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  z-index: 5;
}

.wrapperOptions {
  position: absolute;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #f5f3ee;
  box-shadow: 0px 4px 24px 0px rgba(63, 44, 38, 0.1);
  z-index: 10;
  max-height: 320px;
  overflow: auto;
}

.option {
  position: relative;
  overflow: hidden;
  height: 39px;
  color: color("primary");
  outline: none;
  font-weight: 400;
  background-color: color("white");
  padding: 0 20px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  cursor: pointer;
  transition: all 0.5 ease-in-out;
  &::before {
    content: "";
    width: 90%;
    height: 1px;
    background-color: color("cavern-pink");
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &:last-child {
    &::before {
      content: "";
      display: none;
    }
  }
  &:hover {
    background-color: color("white-dirty");
    font-weight: 600;
    color: #164f43;
  }
}
