@import "../../../../assets/styles/helpers";

a.root {
  position: static;
  @media (max-width: 576px) {
    position: fixed;
  }
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1786c;
  display: block;
  z-index: 100;
  padding: 10px;
  .text {
    color: color("white");
    text-align: center;
    font-size: 16px;
    .link {
      color: color("white");
      text-decoration: underline;
    }
  }
}
