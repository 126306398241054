@import "../../assets/styles/helpers";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: 576px) {
    padding: 32px;
  }
  .mainImage {
    width: 250px;
    height: 250px;
  }
  .text {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: color("primary");
  }
  .textReferralLink {
    margin-bottom: 15px;
    overflow-wrap: anywhere;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
}
