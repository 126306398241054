@import "../../../assets/styles/helpers";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.descriptionText {
  margin-top: 7rem;
}

.infoBox {
  position: relative;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: 130%;
  display: flex;
  justify-content: center;
  text-align: center;

  @include media("lg") {
    position: absolute;
    left: -16rem;
    top: -2rem;
  }
  &:before {
    content: "";
    position: absolute;
    background-image: url("../../../assets/images/svg/onboarding-personal-data-info.svg");
    width: 25rem;
    height: 13.8rem;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    top: 4rem;
  }
}

.infoBoxMobile {
  position: relative;
  font-size: 1.4rem;
  margin-top: -0.5rem;
  font-weight: 500;
  line-height: 130%;
  display: flex;
  justify-content: center;
  text-align: center;

  @include media("lg") {
    position: absolute;
    left: -10rem;
    top: -2rem;
  }

  &:before {
    content: "";
    position: absolute;
    background-image: url("../../../assets/images/svg/onboarding-personal-data-info.svg");
    width: 22rem;
    height: 11.8rem;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    top: -3rem;
  }
}

.iconLeft {
  position: absolute;
  left: -10rem;
  width: 30rem;
  top: 2rem;

  @include media("sm") {
    top: 14rem;
  }

  @include media("lg") {
    left: -40rem;
    margin-bottom: 3rem;
    width: 49rem;
    top: 14rem;
  }
}

.leafIcon {
  position: absolute;
  right: -3.5rem;
  top: 20.5rem;
}

.backgroundIcon {
  position: absolute;
  left: -10rem;
  top: -2rem;
  z-index: -2;

  @include media("lg") {
    left: -85rem;
    top: -12rem;
    z-index: -2;
  }
}
