@import "../../../../assets/styles/helpers";

.element {
  color: color("green");
  font-family: $lora;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 135%;
  white-space: pre-line;
  margin-bottom: 3px;

  @include media("lg") {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}
