@import "../../../assets/styles/helpers";

.title {
  font-size: 24px;
  margin-bottom: 16px;
}

.description {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 60px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 650px;
  width: 100%;
}

.fields {
  position: relative;
  width: 100%;

  @include media("lg") {
    width: 330px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 16px;
}

.leaf1 {
  position: absolute;
  top: -70px;
  left: -40px;
  z-index: -1;

  @include media("lg") {
    top: -60px;
    right: -60px;
    left: auto;
    width: 90px;
    height: auto;
  }
}

.leaf2 {
  position: absolute;
  top: -80px;
  right: -50px;
  z-index: -1;

  @include media("lg") {
    top: -20px;
    left: -105px;
    right: auto;
    width: 120px;
    height: auto;
  }
}

.backgroundIcon1 {
  position: absolute;
  top: 60px;
  left: -100px;
  z-index: -1;

  @include media("lg") {
    top: -200%;
    left: -230%;
  }
}

.backgroundIcon2 {
  position: absolute;
  display: none;

  @include media("lg") {
    display: block;
    top: 200%;
    right: -230%;
    z-index: -1;
  }
}
