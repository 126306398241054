@import "../../assets/styles/helpers";

.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  margin-left: -1px;

  @media (max-width: 1200px) {
    font-size: 28px;
    line-height: 40px;
  }
}
.subTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 20px;
  margin-bottom: 40px;
}
.textField {
  width: 100%;
  .element {
    width: 100% !important;
  }
}
.error {
  color: color("alert");
  margin-bottom: 20px;
  position: absolute;
  height: 20px;
}
.submitButton {
  display: flex !important;
  margin: 0 auto;
  height: 54px;
  width: 180px;
  top: 40px;
}

.success {
  position: relative;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  @media (max-width: 576px) {
    top: 50%;
  }
  .heading {
    width: 100%;
    color: color("primary");
    text-align: center;
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .button {
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
