@import "../../../assets/styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 32px;
  gap: 16px;

  @media (min-width: 575px) {
    padding: 0;
  }
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 32px;

  @media (min-width: 575px) {
    font-weight: 500;
    font-size: 45px;
    line-height: 44px;
  }
}

.content {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;

  @media (min-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.submit {
  margin-top: 40px;
  width: 100%;
  max-width: 235px;
}

.goBack {
  width: 100%;
  max-width: 235px;
}
