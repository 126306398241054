@import "../../assets/styles/helpers";

.element {
  background-color: white;
  padding: 1.6rem 3rem;
  border: 2px solid white;
  border-radius: 25px;
  transition: $time-transition ease;
  max-width: 20rem;

  svg {
    path {
      transition: $time-transition ease;
    }
  }

  &:hover {
    background-color: transparent;
    svg {
      path {
        fill: white;
      }
    }
  }

  &.isBlack {
    background-color: black;
    border: 2px solid black;
    svg {
      path {
        fill: white;
      }
    }
    &:hover {
      background-color: transparent;
      svg {
        path {
          fill: black;
        }
      }
    }
  }
}
