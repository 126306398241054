@import "../../../assets/styles/helpers";

//.header {
//  font-weight: 600;
//  font-size: 3.2rem;
//  line-height: 130%;
//  font-family: $lora;
//  margin-bottom: 2rem;
//
//  @include media('lg') {
//    white-space: nowrap;
//  }
//}

.link {
  color: color("green");
}

.logInText {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.loginBackgroundIcon {
  position: absolute;
  right: -1rem;
  bottom: 0;
  z-index: -1;
}

.headerWrapper {
  margin-bottom: 2rem;

  .iconHeader {
    position: absolute;
    right: -10rem;

    @include media("lg") {
      top: 22%;
      right: -30rem;
    }
  }
}

.header {
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  font-family: "Lora", serif;
  margin-top: 18rem;
  margin-bottom: 2rem;
  max-width: 59%;
  position: relative;
  z-index: 10;
  white-space: nowrap;

  @include media("lg") {
    max-width: 100%;
    text-align: center;
    margin-top: unset;
  }

  div {
    position: relative;
    z-index: 10;

    &:before {
      content: "";
      display: block;
      background-image: url("../../../assets/icons/red-line.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 13.8rem;
      height: 6rem;
      position: absolute;
      left: -1rem;
      bottom: -1.2rem;

      @include media("lg") {
        left: -1.8rem;
        top: -0.6rem;
      }
    }
  }
}
