@import "../../../../assets/styles/helpers";

.element {
  margin-top: 2rem;
  font-size: 1.4rem;
  line-height: 1;
  color: color("alert");

  .iconContainer {
    width: 1.4rem;
    margin-right: 0.8rem;
  }
}

.icon {
  width: 1.4rem !important;
  height: 1.4rem !important;
}
