@import "../../assets/styles/helpers";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 64px;

  @include media("lg") {
    margin-top: -128px;
  }
}

.description {
  max-width: 385px;
  width: 100%;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 64px;
}

.button {
  max-width: 210px;
  width: 100%;
}
