@import "../../assets/styles/helpers";

.track {
  display: flex;
  justify-content: center;
  &Title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: color("primary-light");
    margin-bottom: 15px;
    max-width: 100%;
    text-align: center;
  }
  &Text {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: color("green");
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }

  &Icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    & button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
