@import "../../assets/styles/helpers";

.button {
  font-size: 1.4rem;
  padding: 0.6rem 1.5rem;
  border-radius: 1.3rem;
  line-height: 1.68rem;
  font-weight: 500;
  border: 1px solid color("cavern-pink");
  color: color("primary");
  background-color: transparent;
  transition: background-color $time-transition ease;
  white-space: nowrap;

  @include media("lg") {
    border-radius: 25px;
    font-size: 1.6rem !important;
    padding: 1.2rem 4.5rem 1.3rem 4.5rem;
    line-height: 1.92rem;
  }
}

.onFixedHeader {
  border: none;
  padding: 0.7rem 1.6rem;
  background-color: color("green");
  color: color("white");

  @include media("lg") {
    border-radius: 25px;
    font-size: 1.6rem !important;
    padding: 1.4rem 4.6rem;
  }
}

.menuOpened.menuOpened.menuOpened {
  color: color("white");
  background-color: transparent;
  border: 1px solid color("white");
}
