@import "../../assets/styles/helpers";

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
  @include media("lg") {
    margin-top: 20px;
  }
  &Title {
    max-width: 225px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: color("primary");
  }
  &Title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: color("primary-light");
    margin-bottom: 15px;
    max-width: 100%;
  }
  .qrcode {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
  }
  &Icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    & button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
