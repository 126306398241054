*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-touch-callout: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  border: 0;
}

ol,
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  display: inline;
  color: inherit;
  text-decoration: none;
}

iframe {
  border: 0;
}

table {
  border-spacing: 0;
}

/* Form Elements */
[type="text"] {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
}

[type="number"] {
  -moz-appearance: textfield;
}

[type="search"] {
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

//  Hide original input element
[type="checkbox"],
[type="radio"] {
  position: absolute !important;
  bottom: 0 !important;
  left: 50% !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
  white-space: nowrap !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  border: 0;
  outline: none;
  background: 0 0;
  cursor: pointer;
  -webkit-appearance: button;
  font: inherit;
}

label {
  [for] {
    cursor: pointer;
  }
}

address {
  font-style: normal;
}

video {
  width: 100%;
}

input,
textarea {
  font: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
