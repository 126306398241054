.text {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  //margin-bottom: 7rem;
  width: 30rem;
  &:before {
    content: "";
    position: absolute;
    width: 31rem;
    height: 25rem;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    top: -3rem;
    left: -3px;
  }
}

.textMobile {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  //margin-bottom: 7rem;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    width: 28rem;
    height: 11.8rem;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    top: -3rem;
    left: auto;
  }
}
