@import "../../../../assets/styles/helpers";

.header {
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  font-family: $lora;
  margin-bottom: 2rem;
  position: relative;
  text-wrap: balance;
  z-index: 10;

  @include media("lg") {
    white-space: nowrap;
  }

  div {
    position: relative;
    z-index: 10;

    &:before {
      content: "";
      display: block;
      background-image: url("../../../../assets/icons/red-line.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 13.8rem;
      height: 6rem;
      position: absolute;
      left: -1rem;
      bottom: -1.2rem;

      @include media("lg") {
        left: -1.8rem;
        top: -0.6rem;
      }
    }
  }
}
.subHeader {
  white-space: wrap;
  &__center {
    text-align: center;
  }
}
.logo {
  width: 100%;
  height: 150px;
  position: relative;
  margin-bottom: 16px;
  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 150px;
  }
}
