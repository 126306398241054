@import "../../../../assets/styles/helpers";

.wrapper {
  margin-bottom: 1.3rem;
}

.element {
  position: relative;
}

.Control {
  outline: none !important;
  background-color: transparent;
  padding: 0;
  border-radius: 25px !important;
  border: 1px solid color("input-border") !important;
  z-index: 1;
  width: 100%;
  font-weight: 500;
  color: color("primary");
  font-size: 1.6rem;
  line-height: 3rem;
  box-shadow: none !important;

  &:hover {
    border-color: color("primary") !important;
  }
}

.isAlert {
  .Control {
    border-color: color("alert");

    .Placeholder {
      color: color("alert");
      opacity: 1;
    }
  }
}

.isValid {
  .Control {
    border-color: color("green");

    .Placeholder {
      transform: scale(0.75) translateY(0.5rem);
      top: 0;
      z-index: 2;
      opacity: 1;
      color: color("green");
    }
  }
}

.isFocus {
  .Control {
    .Placeholder {
      transform: scale(0.75) translateY(0.5rem);
      top: 0;
      z-index: 2;
      opacity: 1;
    }
  }
}

.ValueContainer {
  padding: 2.4rem 2.4rem 0.8rem 2.4rem !important;
  line-height: 1;
  flex-wrap: nowrap !important;
}

.Placeholder {
  margin: 0;
  position: absolute;
  top: 0;
  left: 2.4rem;
  transform: translateY(50%);
  z-index: -1;
  transition: $time-transition ease;
  font-size: 1.6rem;
  opacity: 0.6;
  font-weight: 500;
  transform-origin: top left;
  line-height: 3rem;
}

.SingleValue {
  position: relative !important;
  top: 0 !important;
  transform: none !important;
  font-weight: 500;
  color: color("primary") !important;
  font-size: 1.6rem;
  line-height: 1.8rem;
}

.Menu {
  z-index: 100 !important;
}
