@import "../../../../assets/styles/helpers";

.element {
  font-size: 1.6rem;
  line-height: 2.2rem;
  white-space: pre-line;
  color: color("primary-light");
  font-weight: 500;
  text-wrap: balance;

  @include media("lg") {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
