@import "../../assets/styles/helpers";

.root {
  display: flex;
  .step {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #e5dbd8;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background-color: #448b7e;
    }
  }
}
