@import "../../assets/styles/helpers";

.tabs {
  // margin: 32px;
  .tab {
    font-size: 16px;
    line-height: 24px;
    color: color("brown");
    padding-bottom: 14px;
    cursor: pointer;
  }
  .active {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 6px;
      left: 0;
      bottom: 0px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background: color("green");
    }
  }
  .tab {
    margin: 0 20px;
    &:first-child {
      margin-left: 0px;
    }
  }
  &Top {
    position: relative;
    display: flex;
    align-items: center;
    &Border {
      position: absolute;
      width: calc(100% + 80px);
      left: -40px;
      bottom: 0;
      height: 1px;
      background: color("light-gray");
    }
  }
  &Content {
    min-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
