@import "../../../assets/styles/helpers";

.formContainer {
  position: relative;
  z-index: 1;
}

.paymentDetails {
  margin-bottom: 3.6rem;
  background-color: color("white");
  padding: 1.2rem;
  box-shadow: 0px 15px 49px rgba(197, 160, 150, 0.35);
  border-radius: 2.8rem;
  position: relative;
}

.formHeader {
  background-image: url("../../../assets/images/grain-green.png");
  position: relative;
  width: 28rem;
  height: 5.8rem;
  display: flex;
  justify-content: center;
  background-repeat: repeat;
  padding: 1.6rem 2.3rem 0.7rem 2.3rem;
  font-size: 2.4rem;
  border-radius: 35px 35px 0 0;
  line-height: 3.1rem;
  color: #ffffff;
  margin: 3rem auto auto;

  &:before {
    content: "";
    background-image: url("../../../assets/images/leaves-payment-form-header.svg");
    background-repeat: no-repeat;
    background-size: contain;
    right: -5.4rem;
    top: -4.2rem;
    height: 9rem;
    width: 9rem;
    position: absolute;
    z-index: -1;
  }
}

sup {
  line-height: 0;
  vertical-align: baseline;
  position: relative;
  top: -0.2em;
}

.priceText {
  padding: 1rem;
  position: relative;
  z-index: 30;
  margin-top: 3rem;
  opacity: 1;
}

.consentText {
  padding: 1rem;
  position: relative;
  z-index: 30;
  margin-top: 3rem;
  opacity: 0.6;
}

.paymentHeader {
  margin-bottom: 1.7rem;
  p {
    font-size: 2.1rem;
    line-height: 2.7rem;
    color: color("primary");
  }

  .billingText {
    padding-top: 0.2rem;
    color: color("primary");
    opacity: 0.6;
  }
}

.backgroundPhoto {
  position: absolute;
  min-height: 61rem;
}

.enrollmentButton {
  position: relative;
  width: 22.7rem;
  z-index: 1;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 700;
  padding: 2rem 5rem;
  box-shadow: 0 4px 13px rgba(217, 181, 173, 0.8);
  border-radius: 25px;
  background-color: color("primary");
  color: color("white");
  overflow: hidden;
  margin-top: auto;
  border: 2px solid color("primary");
}

.buttonWrapper {
  button {
    white-space: nowrap;
    padding: 2rem 0;
  }
}

.buttonWrapperEnrollment {
  button {
    white-space: nowrap;
    padding: 2rem 12px 2rem 12px;
  }
}

.paddingBottom {
  padding-bottom: 1rem;
}

.paddingBottomEnrollment {
  padding-bottom: 4rem;
}

.label {
  font-weight: 500;
  font-size: 1.6rem;
  margin-top: 2.4rem;
}

.list {
  margin-bottom: 3rem;
}

.visitTimes {
  width: auto;
  margin-bottom: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: color("white");
  border-radius: 3rem;
  padding: 2.1rem 2.8rem;
  position: relative;

  p {
    margin-bottom: 7px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1pt solid #e5dbd8;
  }

  th {
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  td {
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.heading {
  margin-bottom: 2.5rem;
  padding: 0 1rem;

  @include media("lg") {
    max-width: 100%;
  }
  > div {
    color: color("primary");
    font-size: 2.4rem;

    @include media("lg") {
      font-size: 3.2rem;
    }
  }
}

.decoration,
.decoration2 {
  position: absolute;
  z-index: -1;
}

.decoration {
  left: -80rem;
}

.decoration2 {
  right: -80rem;
  transform: rotate(180deg);
  top: 45%;
}

.formHack.formHack.formHack {
  min-height: unset;
  padding: unset;
  margin: unset;
  height: unset;
}

.item {
  background-color: color("white");
  margin-bottom: 1rem;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
  max-width: 90%;
  padding: 2.1rem 2.8rem;
  position: relative;
  left: -1rem;

  @media screen and (min-width: 575px) {
    justify-content: center;
    max-width: 70%;
  }

  @include media("lg") {
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }

  p {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-left: 1.9rem;
  }
}

.form {
  position: relative;
  z-index: 1;
  background-image: url("../../../assets/images/payments_background.png");
  background-repeat: repeat;

  min-height: 100%;

  padding: 2rem 1rem;

  @media screen and (max-width: 575px) {
    background-size: contain;
  }
}

.formBackground {
  border-radius: 35px;
}

.zeroOpacity {
  opacity: 0;
}

.errorMessage {
  margin-bottom: 1.5rem;
}

.backgroundIcon {
  position: absolute;
  top: 23%;
  left: -32rem;
  z-index: -1;

  @include media("lg") {
    top: 0;
    left: -93rem;
    z-index: -1;
  }
}

.backgroundIcon2 {
  position: absolute;
  top: 23%;
  right: 0;
  z-index: -1;
  transform: rotate(180deg);

  @include media("lg") {
    top: 0;
    left: -93rem;
    z-index: -1;
  }
}

.backgroundIcon2 {
  display: none;
  @include media("lg") {
    display: block;
    float: right;
    top: 36%;
    width: 48vw;
    z-index: 12;
    transform: rotate(180deg) translateX(-136rem);
  }
}

.backgroundIcon {
  position: absolute;
  top: 23%;
  left: -32rem;
  z-index: -1;

  @include media("lg") {
    top: 0;
    left: -93rem;
    z-index: -1;
  }
}

.backgroundIcon2 {
  position: absolute;
  top: 23%;
  right: 0;
  z-index: -1;
  transform: rotate(180deg);

  @include media("lg") {
    top: 0;
    left: -93rem;
    z-index: -1;
  }
}

.backgroundIcon2 {
  display: none;
  @include media("lg") {
    display: block;
    float: right;
    top: 36%;
    width: 48vw;
    z-index: 12;
    transform: rotate(180deg) translateX(-136rem);
  }
}

.wrapper {
  padding: 2.4rem;

  @include media("340px") {
    min-height: 62rem;
    padding: 4rem;
    height: 100vh;
  }

  @include media("sm") {
    min-height: 62rem;
    padding: 4rem;
  }

  @include media("lg") {
    min-height: 62rem;
    padding: 4rem;
    height: unset;
  }

  display: flex;
  justify-content: center;
}

.hidden.hidden.hidden {
  display: none !important;
}

.link {
  color: color("green");
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: color("green");
  }
}
