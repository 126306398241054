@import "../../assets/styles/helpers";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  .qrcode {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
  }
  .text {
    max-width: 225px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: color("primary");
  }
}
