@import "../../../assets/styles/helpers";

.wrapper {
  padding: 2.4rem;

  div {
    &:first-child {
      div {
        &:before {
          content: "";
          background-image: url("../../../assets/images/svg/onboardingMemberShip/leafs.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 5.8rem;
          height: 4.5rem;
          position: absolute;
          top: -4rem;
          left: 0.8rem;
        }
      }
    }
  }

  > div {
    &:nth-child(4) {
      margin-bottom: 4rem;
    }
  }
}

.background {
  position: fixed;
  z-index: 111;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize(#333, 0.4);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  button {
    padding: 2rem 2rem;
    white-space: nowrap;
  }
}

.icons {
  position: relative;
  left: -4rem;
  min-width: 10rem;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 3rem;
}

.logInText {
  margin-top: 2.5rem;
  position: relative;
  z-index: 3;
}

.link {
  color: color("green");
}

.leftIcon {
  position: absolute;
  //left: -1rem;
  left: 0rem;
  max-width: 12rem;
  width: 100%;
  bottom: -1rem;
  //bottom: -12%;
  z-index: 4;
}

.backgroundImage {
  position: absolute;
  top: 19%;
  bottom: 1.1rem;

  @media screen and (min-width: 575px) {
    top: 16%;
  }
}

.header {
  max-width: 75%;
}

.box {
  margin-bottom: 2rem;
  position: relative;
  z-index: 3;
}

.desc {
  font-size: 1.8rem;
  line-height: 130%;
}
