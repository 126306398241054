@import "../../assets/styles/helpers";

.element {
  position: fixed;
  z-index: 111;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize(#333, 0.4);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.elementBenefits.elementBenefits {
  align-items: unset;
}

.isBenefits {
  padding: 0;
}

.dialogBenefits {
  //border: 1px solid rgba(173, 173, 173, 0.2);
  //box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04);
  //border-radius: 45px;
  padding: 2rem;
  position: relative;
  background: url("../../assets/images/bg-grain.png");
  width: 100%;
  min-height: 100%;
  overflow: hidden;

  @media screen and (min-width: 575px) {
    border-radius: 45px;
    padding: 2rem 2rem;
    max-width: 50rem;
    margin: auto;
    border: 1px solid rgba(173, 173, 173, 0.2);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04);
  }
}

.dialog {
  border: 1px solid rgba(173, 173, 173, 0.2);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04);
  border-radius: 45px;
  background-color: color("white");
  padding: 2.6rem;
  position: relative;
  width: 100%;
  max-width: 50rem;
  margin: auto;
}
