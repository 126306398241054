@import "../../assets/styles/helpers";

.root {
  display: flex;
  flex-direction: column;
  max-width: 296px;
  border: 1px solid color("bianca");
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;

  &__image {
    position: relative;
    max-height: 200px;
    min-height: 160px;
    background-color: color("white");
    border-bottom: 1px solid color("bianca");
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: color("white");
    height: 114px;
    padding: 19px 20px;
    .heading {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      cursor: pointer;
    }
    .subheading {
      color: color("brown");
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.rootMobile {
  padding: 30px;
  padding-top: 50px;
  height: calc(100% - 50px);
  overflow: auto;
  &__image {
    border-radius: 25px;
    border: 1px solid color("bianca");
    overflow: hidden;
    line-height: 0;
    margin-bottom: 10px;
  }

  .heading {
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    cursor: pointer;
  }

  .authorName {
    color: color("primary");
    font-family: Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 16px;
  }
  .wrapperVideo {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    border-radius: 25px;
    overflow: hidden;
  }
  .wrapperImage {
    display: block;
    margin: 30px auto;
    width: 100%;
    border: 1px solid color("primary");
    border-radius: 25px;
    overflow: hidden;
    .image {
      width: 100%;
    }
  }

  h3 {
    color: color("primary");
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .contentParagraph {
    font-size: 16px;
    line-height: 36px;
    margin-bottom: 10px;
    display: block;
    i {
      line-height: 36px;
      padding: 10px 0;
    }
  }

  .contentListElement {
    margin-bottom: 10px;
  }

  ul,
  ol {
    li {
      padding-left: 30px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        background-color: color("primary");
        border-radius: 50%;
      }
    }
  }
}
