@import "../../../../assets/styles/helpers";

.wrapper {
  margin-bottom: 1.3rem;
}
.inputWrapper {
  background-color: color("white");
  border-radius: 25px;

  .element {
    position: relative;
    z-index: 1;

    &.isFocused {
      .input {
        border-color: color("primary");
      }

      .label {
        transform: scale(0.75) translateY(0.5rem);
        top: 0;
        z-index: 2;
        opacity: 1;
      }
    }

    &.isValid {
      .input {
        border-color: color("green");
      }

      .label {
        color: color("green");
        opacity: 1;
      }
    }

    &.isAlert {
      .input {
        border-color: color("alert");
      }

      .label {
        color: color("alert");
        opacity: 1;
      }
    }

    &.hasPlaceholder {
      .input {
        border-color: color("input-border");
      }
      .label {
        transform: scale(0.75) translateY(0.5rem);
        top: 0;
        z-index: 2;
        opacity: 0.6;
      }
      &.isFocused {
        .input {
          border-color: color("primary");
        }
        .label {
          opacity: 1;
        }
      }
      &.isValid {
        .input {
          border-color: color("green");
        }

        .label {
          color: color("green");
          opacity: 1;
        }
      }

      &.isAlert {
        .input {
          border-color: color("alert");
        }

        .label {
          color: color("alert");
          opacity: 1;
        }
      }
    }
  }
}

.input {
  outline: none !important;
  background-color: transparent;
  padding: 2.2rem 2.4rem 0.8rem 2.4rem;
  border-radius: 25px;
  border: 1px solid color("input-border");
  z-index: 1;
  width: 100%;
  font-weight: 500;
  color: color("primary");
  font-size: 1.6rem;
  line-height: 3rem;
  min-height: 6.2rem;

  &::placeholder {
    opacity: 0.2;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 2.4rem;
  transform: translateY(50%);
  z-index: -1;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-size: 1.6rem;
  opacity: 0.6;
  font-weight: 500;
  transform-origin: top left;
  line-height: 3rem;
}

.eye {
  user-select: none;
  position: absolute;
  right: 2.4rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
