@import "../../assets/styles/helpers";

.element {
  position: fixed;
  width: 100%;
  padding: 1.2rem 0;
  z-index: 120;
  transition: background-color $time-transition ease;

  .inner {
    justify-content: space-between;

    @media screen and (min-width: 993px) {
      justify-content: unset;
    }
  }

  .mainContainer {
    @media (max-width: 335px) {
      padding: 0 0.7rem;
    }
  }

  &.isSticky {
    background-color: color("white");
  }

  justify-content: space-between;

  .menu {
    justify-self: center;
    margin-left: auto;
  }

  .signUpContainer {
    justify-self: end;
    margin-left: auto;
    min-width: 16.7rem;

    .login {
      font-size: 1.4rem;

      @include media("lg") {
        font-size: 1.6rem !important;
      }
    }
  }
}

.paymentSettings {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  cursor: pointer;

  font-size: 1.4rem;
  color: color("primary");

  svg {
    stroke: color("primary");
  }

  @include media("lg") {
    font-size: 1.6rem !important;
  }

  .icon {
    margin-right: 1rem;
  }
}

.logOut {
  justify-self: end;
  //margin-left: auto;
  font-size: 1.2rem;
  min-width: 7.7rem;
  border: 1px solid #dfbfb9 !important;
  border-radius: 13px;
  padding: 0.4rem 1.5rem !important;
  font-family: $lato;

  @include media("lg") {
    font-size: 1.4rem !important;
  }
}

.item {
  margin: 0 1.5rem;
}

.link {
  font-size: 1.6rem;
  line-height: 1.9rem;
  padding: 0 1.5rem;
  transition: color $time-transition ease;

  &.isActive,
  &:hover {
    color: color("green");
  }
}
