@import "../../../../assets/styles/helpers";

.element {
  max-height: 3.4rem;
  height: 100%;
  position: relative;
  z-index: 100;
  margin-bottom: 50px;
}

.back {
  position: absolute;
  left: 1.6rem;
  min-width: 3.4rem;
  width: 3.4rem;
  height: 3.4rem;
  background-color: color("white");
  border-radius: 50%;
  margin-right: 1.4rem;

  svg {
    opacity: 0.6;
  }

  &:hover {
    background-color: color("green");

    svg {
      opacity: 1;

      path {
        stroke: color("white");
      }
    }
  }

  @include media("sm") {
    position: relative;
    left: 0;
  }
}

.bar {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 33px;
  background-color: color("white");
  margin-bottom: 3.2rem;
  @include media("sm") {
    margin-right: 4.3rem;
    margin-bottom: 0;
  }

  &.isDisabled {
    @include media("sm") {
      margin-left: 4.3rem;
    }
  }
}

.marginLeftForStepper {
  margin-left: 4.3rem;
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: color("green");
  max-width: 100%;
  border-radius: 33px;
  width: 0;
  transition: width $time-transition ease;
}

.label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1.2rem;
  font-size: 1.2rem;
  opacity: 0.6;
}
