@import "../../assets/styles/helpers";

.root {
  height: 400px;
}
.mySwiper {
  position: absolute !important;
  width: calc(100% + 40px);
  margin-left: -20px !important;
  padding-left: 20px !important;
}
.swiperSlide {
  width: 70% !important;
  @include media("lg") {
    width: 249px !important;
  }
}
.link {
  text-decoration: underline;
}
.topHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 37px 0;
  &__navigation {
    display: none;
    @include media("lg") {
      display: flex;
      align-items: center;
    }
    &__arrow {
      position: relative;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: color("white");
      cursor: pointer;
      &__disabled {
        opacity: 0.5;
        cursor: default;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__arrowNext {
      margin-left: 11px;
      svg {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }
}

.wrapperCloseButton {
  position: absolute;
  width: 100%;
  height: 50px;
  left: 0;
  top: 0px;
  background-color: color("white");
  z-index: 20;
}
.closeButton {
  position: absolute;
  cursor: pointer;
  padding: 10px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.heading {
  color: color("primary-light");
  font-family: Lora;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
