@import "../../assets/styles/helpers";

.notes {
  @media (max-width: 576px) {
    padding: 32px;
  }
  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &Title {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: color("primary");
    margin-bottom: 20px;
  }
  &Item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &Image {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 15px;
    }
    &Icon {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 15px;
    }
    &Text {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: color("primary");
      max-width: 50px;
    }

    &GrayText {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: color("brown");
      max-width: 130px;
    }
  }
  .wrapperContent {
    display: block;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      height: 50px;
      width: 100%;
      bottom: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1) 60%);
      z-index: 1;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
    &WithoutShadow {
      &::after {
        opacity: 0;
      }
    }
    &Notes {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: color("primary");
      position: relative;
      overflow-y: auto;
      height: calc(100vh - 350px);
      padding-bottom: 40px;
      @include media("sm") {
        height: 405px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
    // &Content {
    //   font-weight: 400;
    //   font-size: 16px;
    //   line-height: 26px;
    // }
  }

  .bookAgainBtn {
    width: calc(100% - 80px);
    font-weight: 600;
    font-size: 16px;
    padding: 18px 31px;
    cursor: pointer;
    margin-top: 16px;

    @media (min-width: 575px) {
      width: 100%;
    }
  }
}
