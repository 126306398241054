@import "../../assets/styles/helpers";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 36px;
  background-color: color("white-dirty");
  position: relative;
  &ButtonContainer {
    display: flex;
  }
  &Logo {
    display: flex;
    align-items: center;
  }
  &Block {
    position: relative;
    display: flex;
    align-items: center;
  }
  &VisitIcon {
    display: none;
  }
  &Text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 24px;
    background: color("bianca");
    font-family: $lora;
    color: color("primary");
  }
  &User {
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-size: 20px;
    line-height: 30px;
  }
}
.close {
  position: absolute;
  top: 32px;
  right: 32px;
  & img {
    width: 42px;
    height: 42px;
  }
}
.drawer {
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: scroll;
  height: 95% !important;
  overflow-x: hidden;
  position: static;
  &MobileMenu {
    position: absolute;
    height: auto !important;
    padding: 0;
  }
}

@media (max-width: 992px) {
  .header {
    &VisitIcon {
      position: relative;
      justify-content: center;
      align-items: center;
      background: color("green");
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 10px;
      display: flex;
      outline: none;
    }
  }
}

@media (max-width: 576px) {
  .header {
    position: fixed !important;
    left: 0;
    right: 0;
    z-index: 3;
    padding: 20px;
  }
}
