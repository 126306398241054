@import "../../../assets/styles/helpers";

.mb-0 {
  margin-bottom: 0;
}

.text-center {
  text-align: center;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mh-100 {
  min-height: 100vh;
}

.mh-90 {
  min-height: 90vh;
}

.mt-xlarge {
  margin-top: 6rem;
}

.mt-large {
  margin-top: 2rem;
}

.mt-small {
  margin-top: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.mb-xlarge {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.hidden {
  display: none !important;
}

.disable-link {
  pointer-events: none;
}

.link {
  text-decoration: underline;
  text-decoration-color: #448b7e;
  cursor: pointer;
}
.link-error {
  text-decoration: underline;
  text-decoration-color: color("alert");
  cursor: pointer;
}

.mt-small {
  margin-top: 1rem !important;
}
