@import "../../assets/styles/helpers";

.home {
  background-color: color("white-dirty");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 1420px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  background: rgba(180, 157, 112, 0.15);
  padding: 24px;
  margin-top: auto;
  & ul {
    display: flex;
    justify-content: center;
    align-items: center;
    & li {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: color("primary-light");
      margin-left: 15px;
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
  width: 100%;
  &Left {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 9;
    min-height: 580px;
    .plan {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 45px;
      &Right {
        display: flex;
        align-items: center;
        .progress {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 70px;
          font-size: 20px;
          line-height: 26px;
          font-family: $lora;
          color: color("primary");
          font-weight: 600;
          border-radius: 50%;
          margin-left: 29px;
          .CircularProgressbar .CircularProgressbar-path {
            stroke: #5b443c !important;
          }
          &Bar {
            position: absolute;
          }
        }
      }
      &Text1 {
        font-size: 20px;
        line-height: 32px;
        color: color("primary-light");
      }
      &Text2 {
        font-family: $lora;
        font-size: 36px;
        font-weight: 500;
        line-height: 44px;
        color: color("primary");
      }
      &Text3 {
        font-size: 20px;
        line-height: 32px;
        color: color("primary");
        font-weight: 700;
      }
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      margin-top: auto;
      margin-bottom: 22px;
      border: 1px solid color("light-gray");
      border-radius: 25px;
      &Block {
        display: flex;
        align-items: center;
      }
      &Icons {
        display: flex;
        align-items: center;
      }
      &Text {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: color("primary-light");
        margin-left: 15px;
        margin-right: 5px;
      }
      &TextReferral {
        font-size: 18px;
        text-align: center;
        margin-bottom: 30px;
      }
      &TextReferralLink {
        color: color("green");
        text-decoration: underline;
      }

      & button {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
  &Right {
    width: 420px;
    background: color("white");
    border-radius: 30px 30px 0px 0px;
    min-height: 500px;
    margin-left: 40px;
    overflow-x: hidden;
    .panel {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px;
      &Text {
        font-size: 28px;
        line-height: 44px;
        font-family: $lora;
      }
      &Button {
        display: flex;
        align-items: center;
        background: color("green");
        border-radius: 20px;
        padding: 13px 24px;
        color: color("white");
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        &:disabled {
          cursor: default;
        }
      }
    }
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: color("white-dirty");
  &Wrap {
    max-width: 300px;
  }

  &Text {
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }

  &Button {
    background: color("green");
    border-radius: 20px;
    padding: 13px 24px;
    color: color("white");
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    margin-bottom: 13px;
  }

  &ButtonSecondary {
    background: #e5dbd8;
    border-radius: 20px;
    padding: 13px 24px;
    color: color("primary");
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 2px;
    background-color: color("light-gray");
  }
  &Wrapper {
    width: 100%;
    margin: auto 0;
  }
  &Title {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  &SubTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: color("mid-gray");
  }
}

.arrow {
  margin-left: 13px;
  vertical-align: middle;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  height: 300px;
}

.ldsRipple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid black;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.fixedButton {
  display: none;
  position: sticky !important;
  left: 0;
  right: 0;
  bottom: 20px;
  width: 320px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: color("green");
  border-radius: 20px;
  padding: 13px 24px;
  color: color("white");
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.drawer {
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: auto;
  height: 95% !important;
  overflow-x: hidden;
  &MobileMenu {
    height: auto !important;
    padding: 0;
  }
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.drawerInfo {
  padding: 30px;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: auto;
  height: 70% !important;
  overflow-x: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  & img {
    width: 42px;
    height: 42px;
  }
}

.linearProgress {
  display: none;
}

.nonVisible {
  display: none !important;
}

.visitIcon {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 3;
  display: none;
  justify-content: center;
  align-items: center;
  background: color("green");
  width: 60px;
  height: 60px;
  border-radius: 50%;
  outline: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media (max-width: 1200px) {
  .container {
    max-width: 100rem;
  }

  .content {
    &Left {
      flex: 8;
      .bottom {
        &Text {
          font-size: 14px;
          line-height: 20px;
          margin-left: 10px;
          margin-right: 5px;
        }

        & button {
          &:not(:last-child) {
            margin-right: 4px;
          }
        }
      }
    }

    &Right {
      flex: 6;
    }
    .plan {
      &Text1 {
        font-size: 16px;
      }
      &Text2 {
        font-size: 26px;
        line-height: 34px;
      }
      &Text3 {
        font-size: 16px;
      }
      &Right {
        display: flex;
        align-items: center;
        .progress {
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 80rem;
  }

  .inVisible {
    display: none;
  }
  .mobile {
    // display: none;
    width: 100%;
    min-height: 300px;
  }

  .contentRight {
    margin-left: 0;
  }
  .visitIcon {
    display: flex;
  }
  .footer ul li {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 720px;
  }
  .content {
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
  .content {
    &Left {
      margin-top: 50px;
      .bottom {
        flex-direction: column;
        &Block {
          & svg {
            display: none;
          }
        }
        &Text {
          font-size: 18px;
        }
        &Icons {
          flex-direction: column;
          width: 100%;
          margin-top: 15px;

          & button {
            width: 100%;
            margin-bottom: 10px;

            & svg {
              height: 50px;
              width: 100%;
            }
            &:not(:last-child) {
              margin-right: 4px;
            }
          }
        }

        &IconsMobile {
          flex-direction: column;
          width: 100%;
          margin-top: 15px;

          & button {
            width: 100%;
            margin-bottom: 10px;

            & svg {
              height: 50px;
              width: 100%;
            }
          }
        }
      }
    }

    .plan {
      margin-bottom: 20px;
      .inVisible {
        display: none;
      }

      &Text1 {
        font-size: 20px;
      }
      &Text2 {
        font-size: 30px;
      }
    }
  }
  .mobilePlan {
    display: flex !important;
    max-width: 100%;
    justify-content: space-between;
    border: 1px solid color("light-gray");
    border-radius: 30px;
    padding: 20px;
    margin-bottom: 20px;
  }
  .footer ul {
    flex-direction: column;
    & li {
      margin-bottom: 5px;
    }
  }
  .inVisible {
    display: none;
  }
  .empty {
    // margin-top: 100px;
    &Wrapper {
      & svg {
        width: 250px;
        height: 250px;
      }
    }
  }
}

@media (max-width: 480px) {
  .contentRight {
    width: 100%;
    padding: 40px 0 0 0;
    .panel {
      &Text {
        font-size: 24px;
      }

      &Button {
        font-size: 15px;
      }
    }
  }
}

.tabsContent {
  margin-top: 30px;
}

.BOOK_APPOINTMENT,
.BOOK_COACHING,
.BOOK {
  height: 100% !important;
}
