@import "../../assets/styles/helpers";

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize(#333, 0.4);
  backdrop-filter: blur(3px);
  z-index: 20;
}
.wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 21;
  background-color: #fff;
  border-radius: 30px;
  width: 500px;
  height: 80vh;
  max-height: 700px;
  padding: 32px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.modalRoot {
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
.root {
  width: calc(100% - 10rem);
  height: calc(100% - 8rem);
  position: absolute;
  top: 4rem;
  left: 4rem;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .heading {
    color: color("primary");
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 0px;
  }
  .authorName {
    color: color("primary");
    font-family: Lora;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin: 10px 0;
  }
  .wrapperVideo {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    border-radius: 25px;
    margin-top: 50px;
    overflow: hidden;
  }
  .wrapperImage {
    display: block;
    margin: 0 auto 30px;
    width: 100%;
    border: 1px solid color("primary");
    border-radius: 25px;
    overflow: hidden;
    line-height: 0;
    .image {
      width: 100%;
    }
  }

  h3 {
    color: color("primary");
    font-family: Lora;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .content {
    p {
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 10px;
      display: block;
      i {
        line-height: 36px;
        padding: 10px 0;
      }
    }

    ul,
    ol {
      li {
        padding-left: 30px;
        margin-bottom: 10px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 18px;
          transform: translateY(-50%);
          width: 5px;
          height: 5px;
          background-color: color("primary");
          border-radius: 50%;
        }
      }
    }
  }
}

.wrapperCloseButton {
  position: absolute;
  top: 32px;
  right: 32px;
}
.closeButton {
  position: sticky;
  cursor: pointer;
  padding: 10px;
  width: 18px;
  height: 18px;
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
