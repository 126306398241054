@import "../../assets/styles/helpers";

.burgerMenuContainer {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../assets/images/dark-green-background.jpg");
  padding-top: 1rem;
  width: 100vw;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 100ms ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  .link {
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 0 1.5rem;
    transition: color $time-transition ease;

    &.isActive,
    &:hover {
      color: color("green");
    }
  }

  .login {
    font-size: 1.4rem;
    color: color("white");
    justify-self: end;
    margin-left: auto;

    @include media("lg") {
      font-size: 1.6rem !important;
    }
  }

  @include media("lg") {
    display: none;
  }

  .leavesImage {
    position: absolute;

    bottom: 12%;
    right: 0;
  }

  .buttonsContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      cursor: pointer;
      user-select: none;
    }
  }

  .navigationMenu {
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    line-height: 3.84rem;
    padding-left: 5.5rem;
    color: color("white");
    font-family: $lora;
    padding-top: 4rem;

    .link {
      padding: 2rem 0;
    }

    .activeLink {
      &::before {
        content: "";
        position: absolute;
        background-color: color("white");
        width: 4rem;
        height: 0.2rem;
        z-index: 1001;
        transition: display 300ms ease;
        transform: translateX(-7rem) translateY(1rem);
      }
    }
  }

  .downloadAppStoreButton {
    width: 87%;
    left: 7%;
    position: absolute;
    bottom: 7%;
  }
}

.open {
  transform: translateX(0);
}
