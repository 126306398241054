@import "../../assets/styles/helpers";

.inputContainer {
  background: transparent;
  border-radius: 20px;
  border: solid 2px rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  min-width: 280px;

  .container {
    display: flex;
    position: relative;
    flex-grow: 1;

    .mailIcon {
      padding: 2rem 1.3rem 2rem 2rem;
    }

    .fieldContainer {
    }

    .error {
      position: absolute;
      bottom: -25px;
      left: 22px;
    }

    .input {
      font-family: $lato;
      background: transparent;
      opacity: 0.8;
      border: none;
      box-shadow: none;
      color: color("white");
      font-size: 1.6rem;
      width: 100%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s !important;
      color: color("white") !important;
      opacity: 0.8 !important;
      background-color: transparent;
      caret-color: color("white");
    }

    .input:-webkit-autofill::first-line {
      font-family: $lato;
      font-size: 1.6rem;
      color: #ffffff;
      opacity: 0.8;
    }

    .input:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }

  .sendImgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    justify-self: flex-end;
    margin: 0 1.3rem 0 1.3rem;
    user-select: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.9);
    }
  }

  ::placeholder {
    color: color("white");
    font-size: 1.6rem;
  }
}

.error {
  color: color("white") !important;
  svg {
    path {
      fill: color("white") !important;
    }
  }
}
