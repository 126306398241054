@import "../../../assets/styles/helpers";

.link {
  color: color("green");
}

.text {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;

  span {
    opacity: 0.8;
    font-size: 1.2rem;
    line-height: 150%;
  }
}

.minHeight {
  min-height: 80vh;
}

.loginBackgroundIcon {
  position: absolute;
  right: -1rem;
  bottom: 0;
  z-index: -1;
}

.submitButton {
  width: auto;
  margin: auto auto 0 auto;
}
.wrapperField {
  margin-bottom: 20px;
}
.submitButton {
  margin: 0 auto;
}
.resetPassInput {
  margin-top: 3rem;
}

.headerWrapper {
  justify-content: flex-start;
  margin-bottom: 2rem;
  width: 100%;
  @include media("lg") {
    width: auto;
    justify-content: center;
  }
}

.bottomText {
  margin-top: 2rem;
}

.iconHeader {
  position: absolute;
  right: -16rem;
  top: -1rem;
  z-index: -1;

  @include media("sm") {
    right: -20rem;
  }

  @include media("md") {
    top: 22%;
    right: -30rem;
  }
}
.partnerErrorMessage {
  color: #e51e42;
  font-size: 13px;
  position: relative;
  margin-bottom: 13px;
  left: 20px;
}

.logInText {
  margin-top: 2.5rem;
}
