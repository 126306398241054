@import "../../assets/styles/helpers";

.doctor {
  background-color: color("white-dirty");
  min-height: 100vh;
  position: relative;
}

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 120rem;
}

.header {
  display: flex;
  align-items: center;
  margin: 40px 0 60px 0;
  &Image {
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      width: 265px;
      height: 265px;
      border-radius: 50%;
      vertical-align: middle;
    }
  }
  &Block {
    margin-left: 50px;
  }
  &Name {
    font-weight: 500;
    font-size: 45px;
    line-height: 52px;
    color: color("primary");
  }
  &Stack {
    display: inline-block;
    background: rgba(180, 157, 112, 0.15);
    border-radius: 10px;
    padding: 6px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    margin-top: 10px;
  }
}

.mobile {
  position: fixed;
  display: flex;
  align-items: center;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  background: color("white");
  padding: 20px;
  &Image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    & img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      vertical-align: middle;
    }
  }
  &Block {
    margin-left: 10px;
  }
  &Name {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: color("primary");
  }
  &Stack {
    display: inline-block;
    background: rgba(180, 157, 112, 0.15);
    border-radius: 10px;
    padding: 6px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    margin-top: 10px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  background: color("white");
  padding: 30px 0;
}

.about {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 120rem;
  background-color: color("white");
}

.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  font-family: $lora;
  margin: 20px 0;
}

.text {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: color("primary-light");
}

@media (max-width: 1200px) {
  .container {
    max-width: 100rem;
  }
  .about {
    max-width: 100rem;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 70rem;
  }
  .about {
    max-width: 70rem;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 60rem;
  }
  .about {
    max-width: 60rem;
  }

  .header {
    &Image {
      img {
        width: 150px;
        height: 150px;
      }
    }

    &Block {
      margin-left: 30px;
    }
    &Name {
      font-size: 38px;
      line-height: 48px;
    }
    &Stack {
      font-size: 12px;
      line-height: 100%;
    }
  }

  .title {
    font-size: 28px;
    line-height: 36px;
  }

  .text {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 576px) {
  .doctor {
    background: color("white");
  }
  .container {
    max-width: 50rem;
  }
  .about {
    max-width: 50rem;
  }

  .header {
    margin: 100px 0 20px;
    &Image {
      img {
        width: 120px;
        height: 120px;
      }
    }

    &Block {
      margin-left: 20px;
    }
    &Name {
      font-size: 28px;
      line-height: 38px;
    }
    &Stack {
      font-size: 10px;
      line-height: 100%;
    }
  }

  .title {
    font-size: 24px;
    line-height: 32px;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
  }
}
