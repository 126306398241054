@import "../../assets/styles/helpers";

.userInfo {
  position: absolute;
  z-index: 3;
  top: 120%;
  right: 0;
  width: 224px;
  background: color("white");
  border: 1px solid #f5f3ee;
  box-shadow: 0px 4px 24px rgba(63, 44, 38, 0.1);
  border-radius: 20px;
  padding: 8px 0;
  &Item {
    display: flex;
    align-items: center;
    padding: 14px;
    &:active {
      background-color: #f2e7d1;
    }
    &:last-child {
      border-top: 1px solid color("light-gray");
    }
    & svg {
      width: 24px;
      width: 24px;
    }
  }
  &Text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 15px;
    color: color("primary");
  }
}

.invisible {
  display: none;
}

.positioned {
  position: static;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
