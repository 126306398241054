@import "../../assets/styles/helpers";

.wrapper {
  width: 100%;
  padding: 38px 26px;
  background-color: color("dawn-pink");

  .heading {
    color: color("primary");
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .subheading {
    color: color("primary-light");
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .card {
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    padding: 25px 20px;
    width: 100%;
    background-color: color("white");
    border: 2px solid color("green");
    border-radius: 25px;
    margin-top: 16px;
    @include media("md") {
      flex-direction: row;
      align-items: center;
    }
    &__left {
      display: flex;
      align-items: center;

      > svg {
        width: 42px;
        height: 42px;
        margin-right: 10px;
        @include media("md") {
          width: 58px;
          height: 58px;
        }
      }
      .heading {
        color: color("green");
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        @include media("md") {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
        }
      }
    }
    .button {
      white-space: nowrap;
      width: 190px;
      height: 48px;
      margin: 6px auto 0;
      @include media("md") {
        margin: 0;
      }
    }
  }
}
.close {
  position: absolute;
  top: 32px;
  right: 32px;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
