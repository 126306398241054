@import "../../assets/styles/helpers";

.element {
  background-color: black;
  padding: 1.6rem 1rem;
  max-height: 7rem;

  border: 2px solid black;
  border-radius: 25px;
  transition: $time-transition ease;

  max-width: 20rem;

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }

  svg {
    height: 5rem;
    max-width: 17.3rem;
  }

  svg {
    path {
      transition: $time-transition ease;
    }
  }

  &:hover {
    background-color: transparent;
    svg {
      path {
        fill: black;
      }
    }
  }

  &.isBlack {
    background-color: black;
    border: 2px solid black;
    svg {
      path {
        fill: black;
      }
    }
    &:hover {
      background-color: transparent;
      svg {
        path {
          fill: black;
        }
      }
    }
  }
}
