@mixin media($name) {
  $width: map-get($breakpoints, $name);

  @if $width {
    @media screen and (min-width: #{$width}px) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-max($name) {
  $width: map-get($breakpoints, $name);

  @if $width {
    @media screen and (max-width: #{$width - 1}px) {
      @content;
    }
  } @else {
    @content;
  }
}
