.modal {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: fit-content;
  max-height: 700px;
  background: #ffffff;
  border: 1px solid #e5dad8;
  border-radius: 30px;
  overflow-y: auto;
  padding: 32px 40px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 30;
  & img {
    width: 42px;
    height: 42px;
  }
}

.overlay {
  position: fixed;
  overflow: hidden;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(63, 44, 38, 0.4);
  backdrop-filter: blur(10px);

  @media (min-width: 1200px) {
    padding: 0;
  }
}
