@import "../../../../assets/styles/helpers";

.form {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &.personalDataStep {
    align-items: center;
    justify-content: center;
  }
  &.formModal {
    padding: 4rem 2rem 2rem 2rem;
    min-height: auto;
    height: auto;
    flex: 1;
    justify-content: space-between;
  }
  @include media("lg") {
    padding-top: 10rem;
  }
}

.descriptionText {
  margin-top: 7rem;
}

.infoBox {
  position: relative;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: 130%;
  display: flex;
  justify-content: center;
  text-align: center;

  @include media("lg") {
    position: absolute;
    left: -16rem;
    top: -2rem;
  }
  &:before {
    content: "";
    position: absolute;
    background-image: url("../../../../assets/images/svg/onboarding-personal-data-info.svg");
    width: 25rem;
    height: 13.8rem;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    top: 4rem;
  }
}

.infoBoxMobile {
  position: relative;
  font-size: 1.4rem;
  margin-top: -0.5rem;
  font-weight: 500;
  line-height: 130%;
  display: flex;
  justify-content: center;
  text-align: center;

  @include media("lg") {
    position: absolute;
    left: -10rem;
    top: -2rem;
  }

  &:before {
    content: "";
    position: absolute;
    background-image: url("../../../../assets/images/svg/onboarding-personal-data-info.svg");
    width: 22rem;
    height: 11.8rem;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    top: -3rem;
  }
}

.leafIcon {
  position: absolute;
  right: -3.5rem;
  top: 20.5rem;
}

.gridWrapper {
  margin-top: 20rem;

  @include media("lg") {
    margin-top: 0;
    max-width: 32rem;
  }
}
.gridWrapperActivateMember {
  max-width: 100%;
}

.backgroundIcon {
  position: absolute;
  left: -10rem;
  top: -2rem;
  z-index: -2;

  @include media("lg") {
    left: -85rem;
    top: -12rem;
    z-index: -2;
  }
}
.errorMessage {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  line-height: 1.3;
  color: color("alert");

  .iconContainer {
    width: 1.4rem;
    margin-right: 0.8rem;
  }
}

.heading {
  margin-bottom: 16px;
}
