@import "../../assets/styles/helpers";

.profile {
  background-color: color("white-dirty");
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  &Success {
    position: fixed;
    top: 20px;
    right: 0;
    background: color("white");
    padding: 20px;
    width: 300px;
    z-index: 4;
    font-size: 18px;
    font-weight: 700;
    font-family: $lora;
    color: color("green");
    transition: all ease-in 0.5s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 4px;
  }
}

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 120rem;
}

.header {
  display: flex;
  align-items: center;
  margin: 40px 0 60px 0;
  min-height: 300px;
  &Image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    & img {
      width: 265px;
      height: 265px;
      border-radius: 50%;
      vertical-align: middle;
    }
  }
  &Block {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
  }
  &Name {
    font-weight: 500;
    font-size: 45px;
    line-height: 52px;
    color: color("primary");
  }
  &Stack {
    display: inline-block;
    background: rgba(180, 157, 112, 0.15);
    border-radius: 10px;
    padding: 6px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
  }

  &Ava {
    position: relative;
    &Image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      font-size: 24px;
      background: color("bianca");
      font-family: $lora;

      font-weight: 600;
      font-size: 100px;
      line-height: 88px;
      z-index: 1;
    }
  }

  &Icon {
    position: absolute;
    top: -10px;
    right: -30px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: color("white");
  padding: 30px 0;
  &Tabs {
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 120rem;
    background-color: color("white");
  }

  &Form {
    &Button {
      display: flex;
      align-self: flex-end;
      margin-left: auto;
      background: color("green");
      border-radius: 20px;
      padding: 13px 44px;
      color: color("white");
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      &:disabled {
        opacity: 0.5;
      }
    }

    &Block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &Wrap {
    max-width: 850px;
    padding-left: 250px;
    padding-top: 40px;
  }
}

.tabs {
  align-self: flex-end;
  padding-left: 250px;
  .tab {
    font-size: 16px;
    line-height: 24px;
    color: color("brown");
    padding-bottom: 14px;
    cursor: pointer;
  }
  .active {
    color: color("green");
    border-bottom: 4px solid color("green");
  }
  .tab:not(:last-child) {
    margin-right: 40px;
  }
  &Top {
    position: relative;
    display: flex;
    align-items: center;
  }
  &Content {
    min-height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.form {
  &Item {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    background-blend-mode: overlay, normal;
    border-radius: 25px;
    cursor: pointer;
    padding: 28px 32px;
    background-color: color("white-dirty");
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    align-items: center;
    &Red {
      color: color("cancel-red");
    }
    &Content {
      display: flex;
      align-items: center;
    }
  }
  &:first-child {
    .groupTitle {
      margin-top: 0;
    }
  }
}

.mobile {
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: color("white");
  &Title {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    font-family: $lora;
  }
}

.arrow {
  vertical-align: middle;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.wrapper {
  margin-bottom: 1.3rem;
}
.inputWrapper {
  background-color: color("white");
  border-radius: 25px;
  .element {
    position: relative;
    z-index: 1;
    width: 280px;
    &.fullWidth {
      width: 100%;
    }
    &.error {
      .input {
        border-color: color("alert");
      }
    }
    &.isFocused {
      .input {
        border-color: color("green");
      }

      .label {
        transform: scale(0.75) translateY(0.5rem);
        top: 0;
        z-index: 2;
        opacity: 1;
      }
    }

    &.isValid {
      .input {
        border-color: color("green");
      }

      .label {
        color: color("green");
        opacity: 1;
      }
    }
  }
}
.groupTitle {
  color: color("primary-light");
  font-family: Lora;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
  margin-top: 43px;
}
.input {
  outline: none !important;
  background-color: transparent;
  padding: 2.2rem 2.4rem 0.8rem 2.4rem;
  border-radius: 25px;
  border: 1px solid color("input-border");
  z-index: 1;
  width: 100%;
  font-weight: 500;
  color: color("primary");
  font-size: 1.6rem;
  line-height: 3rem;
  min-height: 6.2rem;

  &::placeholder {
    opacity: 0.2;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 2.4rem;
  transform: translateY(50%);
  z-index: -1;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-size: 1.6rem;
  opacity: 0.6;
  font-weight: 500;
  transform-origin: top left;
  line-height: 3rem;
}

.eye {
  user-select: none;
  position: absolute;
  right: 2.4rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@media (max-width: 1200px) {
  .container {
    max-width: 100rem;
  }
  .content {
    &Tabs {
      max-width: 100rem;
    }
    &Wrap {
      max-width: 100%;
    }
    &Form {
      &Block {
        flex-direction: column;
        align-items: flex-start;
      }
      &Button {
        align-self: center;
        margin: 0 auto;
      }
    }
  }
  .input {
    max-width: 350px !important;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 80rem;
  }
  .content {
    &Tabs {
      max-width: 80rem;
    }
    &Wrap {
      padding-left: 170px;
    }
  }

  .tabs {
    padding-left: 170px;
  }

  .header {
    &Ava {
      &Image {
        width: 130px;
        height: 130px;
        font-size: 80px;
      }
    }
    &Name {
      font-size: 40px;
    }
    &Icon {
      height: 100px;
    }
    &Block {
      margin-left: 30px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 60rem;
  }
  .content {
    &Tabs {
      max-width: 60rem;
    }
    &Wrap {
      padding-left: 0;
    }
    &Form {
      &Block {
        align-items: center;
      }
    }
  }

  .tabs {
    padding-left: 200px;
  }

  .header {
    &Ava {
      &Image {
        width: 150px;
        height: 150px;
      }
    }
  }

  .form {
    flex-direction: column;
    align-items: center;
    &Item {
      padding: 20px 30px;
    }
  }
}

@media (max-width: 576px) {
  .profile {
    background-color: color("white");
  }
  .wrapper {
    width: 100%;
  }

  .inputWrapper {
    display: flex;
    justify-content: center;

    .element {
      max-width: 350px;
      width: 100%;
    }
  }

  .header {
    margin: 150px 0 20px;
    min-height: auto;
    &Icon {
      display: none;
    }
    &Ava {
      &Image {
        width: 100px;
        height: 100px;
        font-size: 50px;
        line-height: 60px;
      }
    }
    &Name {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .content {
    &Wrap {
      padding-top: 0;
    }
    &Form {
      &Button {
        max-width: 350px;
        width: 100%;
        justify-content: center;
      }
    }
  }
  .tabs {
    display: none;
  }
  .form {
    &Item {
      font-size: 16px;
      line-height: 24px;
      padding: 24px;
      &Content {
        .title {
          display: inline;
          vertical-align: middle;
        }
        .requiredStarIcon {
          width: 10px;
          margin-left: 5px;
          margin-bottom: 10px;
        }
        svg,
        img {
          display: inline;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
}

.badge {
  border-radius: 10px;
  width: 105px;
  height: 27px;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;

  &.required {
    background-color: #ff9a83;
    color: color("primary");
  }
  &.completed {
    background-color: color("green");
    color: color("white");
  }
}
