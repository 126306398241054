@import "../../../../assets/styles/helpers";

.element {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  &.isFocused {
    .input {
      border-color: color("primary");
    }

    .label {
      transform: scale(0.75) translateY(0.5rem);
      top: 0;
      z-index: 2;
      opacity: 1;
    }
  }

  &.isValid {
    .input {
      border-color: color("green");
    }

    .label {
      color: color("green");
      opacity: 1;
    }
  }

  &.isAlert {
    .input {
      border-color: color("alert");
    }

    .label {
      color: color("alert");
      opacity: 1;
    }
  }
}

.sucessText {
  margin-top: 1.5rem;
  margin-left: 1.5rem;
}

.input {
  outline: none !important;
  background-color: transparent;
  padding: 2.2rem 8rem 0.8rem 2.4rem;
  border-radius: 25px;
  border: 1px solid color("cavern-pink");
  z-index: 1;
  width: 100%;
  font-weight: 500;
  color: color("primary");
  font-size: 1.6rem;
  line-height: 3rem;
  min-height: 6.2rem;
}

.label {
  position: absolute;
  top: 0;
  left: 2.4rem;
  transform: translateY(50%);
  z-index: -1;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-size: 1.6rem;
  opacity: 0.6;
  font-weight: 500;
  transform-origin: top left;
  line-height: 3rem;
}

.tool {
  display: flex;
  position: absolute;
  right: 2.4rem;
  z-index: 2;
}

.button {
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 0.6;
  color: color("primary");
  transition: $time-transition ease;

  &.isValid,
  &:not(.isDisabled):hover {
    color: color("green");
    opacity: 1;
  }

  &.isDisabled {
    cursor: default;
  }
}

.spinner {
  width: 2.4rem;
  height: 2.4rem;
  circle,
  path {
    stroke: color("green");
  }
}
