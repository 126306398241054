@import "../../../assets/styles/helpers";

.wrapper {
  position: relative;
  left: -1rem;
  width: calc(100% + 2rem);
  margin-top: 29px;
  padding: 24px;
  background-color: color("bianca");
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  @include media("lg") {
    left: 50%;
    transform: translateX(-50%);
    padding: 34px 52px;
    border-radius: 55px;
    width: fit-content;
  }

  &__card {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 24px;
    background-color: color("white");
    border-radius: 30px;
    height: 340px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }

    @include media("lg") {
      display: flex;
      justify-content: space-between;
      width: 756px;
      height: 140px;
    }

    &.active {
      padding: 24px;
      position: relative;
      box-shadow: 0px 0px 0px 3px color("primary");
      .button {
        border: 1px solid color("primary");
        background-color: color("pink");
      }
    }

    &__texts {
      @include media("lg") {
        margin-left: 15px;
        width: 490px;
      }
      .description {
        font-size: 16px;
        line-height: 22.4px;
        color: color("primary");
        .underline {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .button {
    width: calc(100% - 58px);
    height: 51px;
    color: color("primary");
    background-color: transparent;
    border: 1px solid color("pink");
    position: absolute;
    border-radius: 18px;
    box-shadow: none;
    bottom: 36px;
    padding-left: 44px;
    > svg {
      position: absolute;
      width: 19px;
      left: calc(50% - 50px);
    }
    @include media("lg") {
      justify-content: flex-start !important;
      width: 132px;
      height: 48px;
      position: relative;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.BackgroundLeftIcon,
.BackgroundRightIcon {
  display: none;
}

@include media("lg") {
  .BackgroundLeftIcon {
    display: block;
    position: fixed;
    left: 0;
    top: 20%;
  }
  .BackgroundRightIcon {
    display: block;
    position: fixed;
    right: 0;
    top: 40%;
  }
}

.heading {
  font-size: 24px;
  margin: 14px 0 10px;
  line-height: 33.6px;
  @include media("lg") {
    font-size: 24px;
    margin: 0 0 10px;
  }
}
.subHeading {
  width: 100%;
  text-align: center;
  font-size: 18px;
  @include media("lg") {
    white-space: nowrap;
  }
}
.paragraph {
  text-align: left;
}
.nextButton {
  margin-top: 39px;
  margin-bottom: 48px;
}
