@import "../../assets/styles/helpers";

.doctor {
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid color("light-gray");
  border-radius: 25px;
  padding: 20px 20px 20px 24px;
  margin-bottom: 20px;
  &Left {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &Notes {
    display: flex;
    align-items: center;
    background: rgba(180, 157, 112, 0.15);
    border-radius: 10px;
    padding: 4px 10px 4px 6px;
    &Text {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: color("brown");
    }
  }
  &Info {
    margin-left: 20px;
    padding-left: 15px;
    border-left: 1px solid color("light-gray");
    width: 100%;
    &Top {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    &Block {
      margin-left: 10px;
    }
    &Bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: color("primary");
    }
  }
  &Image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  &Name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: color("primary");
  }
  &Stack {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: color("brown");
  }
  &Date {
    &Top {
      font-size: 14px;
      line-height: 16px;
      color: color("brown");
      margin-bottom: 4px;
    }

    &Middle {
      font-size: 24px;
      line-height: 32px;
      color: color("primary");
      font-family: $lora;
      margin-bottom: 4px;
    }

    &Bottom {
      font-size: 14px;
      line-height: 16px;
      color: color("primary");
    }
  }
  &Right {
    position: relative;
  }

  .drop {
    position: absolute;
    z-index: 1;
    top: 60%;
    right: 0;
    margin-bottom: 20px;
    width: 224px;
    background: color("white");
    border: 1px solid #f5f3ee;
    box-shadow: 0px 4px 24px rgba(63, 44, 38, 0.1);
    border-radius: 20px;
    padding: 8px 0;
    &Item {
      display: flex;
      align-items: center;
      padding: 14px;
      cursor: pointer;
      &:nth-child(2) {
        border-top: 1px solid color("light-gray");
      }
    }
    &Text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-left: 15px;
      color: color("primary");
    }
  }
}

@media (max-width: 480px) {
  .doctor {
    padding: 20px;
    &Date {
      &Top {
        font-size: 12px;
        line-height: 14px;
      }
      &Middle {
        font-size: 20px;
        line-height: 30px;
      }
      &Bottom {
        font-size: 12px;
        line-height: 18px;
      }
    }
    &Name {
      font-size: 16px;
      line-height: 24px;
    }
    &Stack {
      font-size: 11px;
      line-height: 16px;
    }

    &Info {
      margin-left: 10px;
    }
    &NotesText {
      font-size: 11px;
      line-height: 20px;
    }
  }
}
