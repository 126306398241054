@import "../../assets/styles/helpers";

.element {
  &.is-h1 {
    font-family: $lora;
    font-size: 2.2rem;
    line-height: 2.6rem;
    font-weight: 500;

    @include media("lg") {
      font-size: 4.5rem;
      line-height: 5.6rem;
    }
  }

  &.is-h2 {
    font-family: $lora;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 500;

    @include media("lg") {
      font-size: 3.5rem;
      line-height: 5rem;
    }
  }

  &.is-h3 {
    font-size: 2.4rem;
    line-height: 2.3rem;
    font-family: $lora;
    font-weight: 500;

    @include media("lg") {
      line-height: 3.1rem;
    }
  }
  //
  //&.is-h4 {
  //	font-weight: 600;
  //	font-size: 1.6rem;
  //	line-height: 1.8rem;
  //	margin-bottom: 1.6rem;
  //
  //}
}
