@import "src/assets/styles/helpers";

.area {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  &.hasCols-10 {
    grid-template-columns: repeat(10, 1fr);
  }
}

.element {
  grid-column: span 12;

  &.hasSize-2 {
    grid-column: span 2;
  }
  &.hasSize-3 {
    grid-column: span 3;
  }
  &.hasSize-4 {
    grid-column: span 4;
  }
  &.hasSize-5 {
    grid-column: span 5;
  }
  &.hasSize-6 {
    grid-column: span 6;
  }
  &.hasSize-7 {
    grid-column: span 7;
  }
  &.hasSize-8 {
    grid-column: span 8;
  }
  &.hasSize-9 {
    grid-column: span 9;
  }
  &.hasSize-10 {
    grid-column: span 10;
  }
  &.hasSize-11 {
    grid-column: span 11;
  }
  &.hasSize-12 {
    grid-column: span 12;
  }

  @include media("xs") {
    grid-column: span 12;
    &.hasSize-xs-2 {
      grid-column: span 2;
    }
    &.hasSize-xs-3 {
      grid-column: span 3;
    }
    &.hasSize-xs-4 {
      grid-column: span 4;
    }
    &.hasSize-xs-5 {
      grid-column: span 5;
    }
    &.hasSize-xs-6 {
      grid-column: span 6;
    }
    &.hasSize-xs-7 {
      grid-column: span 7;
    }
    &.hasSize-xs-8 {
      grid-column: span 8;
    }
    &.hasSize-xs-9 {
      grid-column: span 9;
    }
    &.hasSize-xs-10 {
      grid-column: span 10;
    }
    &.hasSize-xs-11 {
      grid-column: span 11;
    }
    &.hasSize-xs-12 {
      grid-column: span 12;
    }
  }

  @include media("md") {
    grid-column: span 12;
    &.hasSize-md-2 {
      grid-column: span 2;
    }
    &.hasSize-md-3 {
      grid-column: span 3;
    }
    &.hasSize-md-4 {
      grid-column: span 4;
    }
    &.hasSize-md-5 {
      grid-column: span 5;
    }
    &.hasSize-md-6 {
      grid-column: span 6;
    }
    &.hasSize-md-7 {
      grid-column: span 7;
    }
    &.hasSize-md-8 {
      grid-column: span 8;
    }
    &.hasSize-md-9 {
      grid-column: span 9;
    }
    &.hasSize-md-10 {
      grid-column: span 10;
    }
    &.hasSize-md-11 {
      grid-column: span 11;
    }
    &.hasSize-md-12 {
      grid-column: span 12;
    }
  }
  @include media("lg") {
    grid-column: span 12;
    &.hasSize-lg-2 {
      grid-column: span 2;
    }
    &.hasSize-lg-3 {
      grid-column: span 3;
    }
    &.hasSize-lg-4 {
      grid-column: span 4;
    }
    &.hasSize-lg-5 {
      grid-column: span 5;
    }
    &.hasSize-lg-6 {
      grid-column: span 6;
    }
    &.hasSize-lg-7 {
      grid-column: span 7;
    }
    &.hasSize-lg-8 {
      grid-column: span 8;
    }
    &.hasSize-lg-9 {
      grid-column: span 9;
    }
    &.hasSize-lg-10 {
      grid-column: span 10;
    }
    &.hasSize-lg-11 {
      grid-column: span 11;
    }
    &.hasSize-lg-12 {
      grid-column: span 12;
    }
  }
  @include media("xl") {
    grid-column: span 12;
    &.hasSize-xl-2 {
      grid-column: span 2;
    }
    &.hasSize-xl-3 {
      grid-column: span 3;
    }
    &.hasSize-xl-4 {
      grid-column: span 4;
    }
    &.hasSize-xl-5 {
      grid-column: span 5;
    }
    &.hasSize-xl-6 {
      grid-column: span 6;
    }
    &.hasSize-xl-7 {
      grid-column: span 7;
    }
    &.hasSize-xl-8 {
      grid-column: span 8;
    }
    &.hasSize-xl-9 {
      grid-column: span 9;
    }
    &.hasSize-xl-10 {
      grid-column: span 10;
    }
    &.hasSize-xl-11 {
      grid-column: span 11;
    }
    &.hasSize-xl-12 {
      grid-column: span 12;
    }
  }
  @include media("fhd") {
    grid-column: span 12;
    &.hasSize-fhd-2 {
      grid-column: span 2;
    }
    &.hasSize-fhd-3 {
      grid-column: span 3;
    }
    &.hasSize-fhd-4 {
      grid-column: span 4;
    }
    &.hasSize-fhd-5 {
      grid-column: span 5;
    }
    &.hasSize-fhd-6 {
      grid-column: span 6;
    }
    &.hasSize-fhd-7 {
      grid-column: span 7;
    }
    &.hasSize-fhd-8 {
      grid-column: span 8;
    }
    &.hasSize-fhd-9 {
      grid-column: span 9;
    }
    &.hasSize-fhd-10 {
      grid-column: span 10;
    }
    &.hasSize-fhd-11 {
      grid-column: span 11;
    }
    &.hasSize-fhd-12 {
      grid-column: span 12;
    }
  }
}
