@import "../../../../assets/styles/helpers";

.element {
  display: block;
  position: relative;
  width: 22.7rem;
  height: 62px;
  z-index: 1;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 700;
  padding: 2rem 5rem;
  box-shadow: 0 4px 13px rgba(217, 181, 173, 0.8);
  border-radius: 25px;
  background-color: color("green");
  color: color("white");
  overflow: hidden;
  border: 2px solid color("green");
  transition: background-color $time-transition ease, color $time-transition ease;

  &.isDisabled {
    opacity: 0.4;
  }

  &:not(.isDisabled) {
    &:hover {
      @include media("sm") {
        background-color: white;
        color: color("green");
      }
    }
  }

  &.isSubmitting {
    cursor: default;
    padding: 1.2rem 5rem;
    &:hover {
      background-color: color("green");
    }
  }
}

.loader {
  width: 3.2rem;
  height: 3.2rem;
}
